// // essaySlice.js
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   essay: "",
//   topic: "",
// };

// export const essaySlice = createSlice({
//   name: "essay",
//   initialState,
//   reducers: {
//     setEssay: (state, action) => {
//       state.essay = action.payload;
//     },
//     clearEssay: (state) => {
//       state.essay = "";
//     },
//     setTopic: (state, action) => {
//       state.topic = action.payload;
//     },
//     clearTopic: (state) => {
//       state.topic = "";
//     },
//   },
// });

// export const { setEssay, clearEssay, setTopic, clearTopic } =
//   essaySlice.actions;

// export default essaySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  essayType: "",
  wordCount: "",
  includeHeading: false,
  isLoading: false,
  description: "",
  stop: false,
  progress: 0,
};

export const essaySlice = createSlice({
  name: "essay",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    resetProgress: (state) => {
      state.progress = 0;
    },
    addEssayProject: (state, action) => {
      const project = {
        title: action.payload,
        essay: "",
        topic: "",
      };
      state.projects.push(project);
    },
    setEssayContent: (state, action) => {
      const { title, essay } = action.payload;
      const project = state.projects.find((p) => p.title === title);
      if (project) {
        project.essay = essay;
      }
    },
    setEssayTopic: (state, action) => {
      const { title, topic } = action.payload;
      const project = state.projects.find((p) => p.title === title);
      if (project) {
        project.topic = topic;
      }
    },
    clearEssay: (state, action) => {
      const project = state.projects.find((p) => p.title === action.payload);
      if (project) {
        project.essay = "";
      }
    },
    clearTopic: (state, action) => {
      const project = state.projects.find((p) => p.title === action.payload);
      if (project) {
        project.topic = "";
      }
    },
    setEssayType: (state, action) => {
      state.essayType = action.payload;
    },
    clearEssayType: (state) => {
      state.essayType = initialState.essayType;
    },
    setWordCount: (state, action) => {
      state.wordCount = action.payload;
    },
    clearWordCount: (state) => {
      state.wordCount = initialState.wordCount;
    },
    setIncludeHeading: (state, action) => {
      state.includeHeading = action.payload;
    },
    clearIncludeHeading: (state) => {
      state.includeHeading = initialState.includeHeading;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearIsLoading: (state) => {
      state.isLoading = initialState.isLoading;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    clearDescription: (state) => {
      state.description = initialState.description;
    },
    setStop(state, action) {
      state.stop = action.payload;
    },
    clearStop(state) {
      state.stop = false;
    },
    clearAllEssays: () => initialState,
  },
});

export const {
  addEssayProject,
  setEssayContent,
  setEssayTopic,
  clearEssay,
  clearTopic,
  clearAllEssays,
  setEssayType,
  clearEssayType,
  setWordCount,
  clearWordCount,
  setIsLoading,
  clearIsLoading,
  setDescription,
  clearDescription,
  setIncludeHeading,
  clearIncludeHeading,
  setStop,
  clearStop,
  setProgress,
  resetProgress,
} = essaySlice.actions;

export default essaySlice.reducer;
