import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { getUser } from "../Admin/userSlice";
import { useNavigate } from "react-router-dom";
import ReactGA from "../../Analytics";

export default function Confirmation({ onClose, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   handleSubmitSession();
  // }, []);

  // const handleSubmitSession = async () => {
  //   try {
  //     setLoading(true);
  //     let { data } = await Repo.subscribe({
  //       request: {
  //         method: "createPortalSession",
  //         data: {
  //           sessionId: id,
  //           email: localStorage.getItem("email"),
  //         },
  //       },
  //     });

  //     if (data.response.data.sessionUrl) {
  //       setLoading(false);

  //       dispatch(getUser());
  //       navigate("/subscription");
  //       Toast("success", "Plan Subscribed Successfully");
  //     } else {
  //       setLoading(false);
  //       Toast("error", data.response.status.statusMessage);
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      // const session_id = urlSearchParams.get("session_id");
      const session_id = id;
      if (!localStorage.getItem(`payment_success_${session_id}`)) {
        localStorage.setItem(`payment_success_${session_id}`, true);
        console.log("Pushing to dataLayer:", {
          success: true,
          session_id,
          page: window.location.pathname,
        });

        // Push to dataLayer for GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "payment_success", // Custom event name
          success: true,
          session_id: session_id,
          page: window.location.pathname,
        });

        console.log("Sending to GA4:", {
          page: window.location.pathname,
          title: "Payment Success",
        });
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: "Payment Success",
        });

        // Call the backend API
        handleSubmitSession(session_id);
      }
    }
  }, []);

  // const handleSubmitSession = async (id) => {
  //   try {
  //     setLoading(true);
  //     let { data } = await Repo.subscribe({
  //       request: {
  //         method: "createPortalSession",
  //         data: {
  //           sessionId: id,
  //           email: localStorage.getItem("email"),
  //         },
  //       },
  //     });

  //     if (data.response.data.sessionUrl) {
  //       // setLoading(false);
  //       setTimeout(() => {
  //         dispatch(getUser());
  //         setLoading(false);
  //         Toast("success", "Plan Subscribed Successfully");
  //         navigate("/subscription");

  //         window.dataLayer = window.dataLayer || [];
  //         window.dataLayer.push({
  //           event: "Payment Success Two",
  //           page: window.location.pathname,
  //           title: "Payment",
  //         });
  //       }, 5000);
  //       // navigate("/subscription");

  //       // window.dataLayer = window.dataLayer || [];
  //       // window.dataLayer.push({
  //       //   event: "Payment Success Two",
  //       //   page: window.location.pathname,
  //       //   title: "Payment",
  //       // });
  //       // setTimeout(() => {
  //       // Toast("success", "Plan Subscribed Successfully");
  //       // }, 3000);
  //     } else {
  //       setLoading(false);
  //       Toast("error", data.response.status.statusMessage);
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     setLoading(false);
  //   }
  // };

  const handleSubmitSession = async (id) => {
    if (!localStorage.getItem(`session_handled_${id}`)) {
      localStorage.setItem(`session_handled_${id}`, true);

      try {
        setLoading(true);
        let { data } = await Repo.subscribe({
          request: {
            method: "createPortalSession",
            data: {
              sessionId: id,
              email: localStorage.getItem("email"),
            },
          },
        });

        if (data.response.data.sessionUrl) {
          setTimeout(() => {
            dispatch(getUser());
            setLoading(false);
            Toast("success", "Plan Subscribed Successfully");
            navigate("/subscription");
          }, 5000);

          let count = parseInt(
            localStorage.getItem("paymentSuccessTwoCount") || "0",
            10
          );
          count += 1;
          localStorage.setItem("paymentSuccessTwoCount", count);
          console.log("Payment Success Two Event Fired Count:", count);

          // Log event only once
          window.dataLayer = window.dataLayer || [];
          console.log("Window Data Lyer fired", window.dataLayer);
          window.dataLayer.push({
            event: "Payment Success Two",
            page: window.location.pathname,
            title: "Payment",
          });
        } else {
          setLoading(false);
          Toast("error", data.response.status.statusMessage);
        }
      } catch (err) {
        console.error("Error:", err);
        setLoading(false);
      }
    }
  };

  const toggle = () => {
    if (!loading) {
      onClose();
    }
  };
  return (
    <Dialog open={true} onClose={toggle} fullWidth maxWidth="xs">
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          {loading && <Typography>Please Wait</Typography>}

          <input type="hidden" id="session-id" name="session_id" value={id} />
          {loading && (
            <TailSpin height="37" width="37" color="#5AB7BF" visible={true} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
