import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, styled } from "@mui/material";
import ResearchPalLogo from "../../assets/svg/ResearchPalLogo.svg";
import {
  BtnAPI,
  BtnBlog,
  BtnAff,
  BtnFAQs,
  BtnLogin,
  BtnPrice,
  BtnTermsAndCondition,
  BtnUniversity,
  MenuBox,
  RootStyle,
} from "../styles";
import BetaLogMini from "../../assets/svg/BetaLogoMini.svg";
import { Link, useNavigate } from "react-router-dom";
import { ResearchPalMini } from "../../assets/svg/LoopLogo";
// import ResearchPal from "../../assets/images/NewImages/ResearchPal.svg";
import ResearchPal from "../../assets/images/NewImages/landing-RP.svg";
function LandingHeader({ onClick }) {
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setNavbar((prev) => !prev);
  };

  return (
    <RootStyle
      sx={{
        backgroundColor: "#fff !important",
      }}
    >
      <Box
        sx={{
          height: "50px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          px: "24px",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          {/* <img
            src={ResearchPalLogo}
            alt="ResearchPal Logo"
            style={{ height: "42px" }}
          /> */}
          <Link to="/">
            {/* <ResearchPalMini /> */}
            {/* <img src={ResearchPal} alt="Beta Logo" style={{ height: "16px" }} /> */}
            <img src={ResearchPal} alt="Beta Logo" style={{ height: "36px" }} />
          </Link>
        </Stack>

        <Box sx={{ color: "black", display: { xs: "block", md: "none" } }}>
          {navbar ? (
            <CloseIcon
              sx={{ color: "#000", cursor: "pointer" }}
              onClick={toggle}
            />
          ) : (
            <MenuIcon
              sx={{ color: "#000", cursor: "pointer" }}
              onClick={toggle}
            />
          )}
        </Box>

        <Box
          sx={{
            width: "80%",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: "22px",
          }}
        >
          <BtnAff
            onClick={() =>
              window.open("https://researchpal.tolt.io/login", "_blank")
            }
          >
            Affiliate
          </BtnAff>
          <BtnPrice onClick={() => onClick(navigate("/features"))}>
            Features
          </BtnPrice>
          <BtnUniversity onClick={() => onClick(navigate("/universities"))}>
            Universities
          </BtnUniversity>
          <BtnBlog onClick={() => onClick(navigate("/blog"))}>Blog</BtnBlog>
          {/* <BtnBlog
            onClick={() =>
              (window.location.href = "https://blog.researchpal.co/")
            }
          >
            Blog
          </BtnBlog> */}

          <BtnPrice onClick={() => onClick(navigate("/pricing"))}>
            Pricing
          </BtnPrice>
          <BtnFAQs onClick={() => onClick(navigate("/faqs"))}>FAQs</BtnFAQs>
          <BtnAPI onClick={() => onClick(navigate("/api"))}>API</BtnAPI>
          <BtnTermsAndCondition
            onClick={() => onClick(navigate("/termsandconditions"))}
          >
            Terms
          </BtnTermsAndCondition>
          <BtnLogin onClick={() => onClick("loginModal")}>Login</BtnLogin>
        </Box>
      </Box>

      {navbar && (
        <MenuBox>
          <BtnAff
            sx={{ my: 0.5 }}
            fullWidth
            onClick={() =>
              window.open("https://researchpal.tolt.io/login", "_blank")
            }
          >
            Affiliate
          </BtnAff>
          <BtnPrice
            sx={{ my: 0.5 }}
            fullWidth
            onClick={() => onClick(navigate("/features"))}
          >
            Features
          </BtnPrice>
          <BtnUniversity onClick={() => onClick(navigate("/universities"))}>
            Universities
          </BtnUniversity>
          <BtnBlog
            sx={{ my: 0.5 }}
            fullWidth
            onClick={() => onClick(navigate("/blog"))}
            // onClick={() =>
            //   (window.location.href = "https://blog.researchpal.co/")
            // }
          >
            Blog
          </BtnBlog>
          <BtnPrice
            sx={{ my: 0.5 }}
            fullWidth
            onClick={() => onClick(navigate("/pricing"))}
          >
            Pricing
          </BtnPrice>
          <BtnFAQs fullWidth onClick={() => onClick(navigate("/faqs"))}>
            FAQs
          </BtnFAQs>
          <BtnAPI onClick={() => onClick(navigate("/api"))}>API</BtnAPI>
          <BtnPrice
            sx={{ my: 0.5 }}
            fullWidth
            onClick={() => onClick(navigate("/termsandconditions"))}
          >
            {/* Terms & Conditions */}
            Terms
          </BtnPrice>
          <BtnLogin fullWidth onClick={() => onClick("loginModal")}>
            Login
          </BtnLogin>
        </MenuBox>
      )}
    </RootStyle>
  );
}

export default LandingHeader;
