import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useGetUrl() {
  const { pathname } = useLocation();
  const newRegex = /^(?:[^/]*\/){3}(.*)/;
  const newMatch = pathname.match(newRegex);
  const newUrl = newMatch[1];

  // const urlSplit = pathname.split(`/projects/papers-insights/`);
  // const newUrl = urlSplit[1];
  // console.log("urlSplit:", urlSplit);

  const regex = /\/([^\/]+)$/;
  const match = pathname.match(regex);
  const url = match[1];

  const getTab = useMemo(() => {
    if (url === "document") {
      return "document";
    } else if (url === "literature-review") {
      return "literature-review";
    } else if (url === "references") {
      return "references";
    } else if (url === "library") {
      return "library";
    } else if (url === "essay-writing") {
      return "essay-writing";
    } else if (url === "papers-insights") {
      return "papers-insights";
    } else if (url === "search-papers") {
      return "search-papers";
    } else if (newUrl === `search-papers/${url}`) {
      return "search-papers";
    } else {
      return "";
    }
  }, [pathname]);

  return getTab;
}

export default useGetUrl;
