import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getFeedbackEmails } from "../../apiservice";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { CircularProgress, FormControl, FormControlLabel } from "@mui/material";
import { Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { baseDomain } from "../../repo/Repository";
import { useDispatch } from "react-redux";
import { clearUser } from "./userSlice";
import FeedBacks from "./FeedBacks";
import Repo from "../../repo/Repo";
import Toast from "../../components/Toast";
import { TailSpin } from "react-loader-spinner";
import { CBBox } from "../styles";
import Limitations from "./Limitations";
// import { DatePicker, Segmented } from "antd";
import moment from "moment";
// import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import SignupChart from "./SignupChart";
import SignupCustomChart from "./SignupCustom";

const customStyles = {
  rows: {
    style: {
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      background: "transparent",
    },
  },
  header: {
    style: {
      // borderTopRightRadius: "5px",
      // borderTopLeftRadius: "5px",
      borderRadius: "5px",
      marginBottom: "5px",
      backgroundColor: "#01A35F",
      color: "#fff",
      textAlign: "center",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "#01A35F",
      borderBottomRightRadius: "5px",
      borderBottomLeftRadius: "5px",
      marginBottom: "5px",
      padding: "5px",
      minHeight: "40px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#01A35F",
      color: "white",
      fontSize: "16px",
      borderRadius: "5px",
      marginBottom: "5px",
    },
  },
  cells: {
    style: {
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      display: "flex",
      justifyContent: "center",
    },
  },
};

const Protal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("user");
  console.log("Selected Tab", selectedTab);
  const [open, setOpen] = useState(false);
  const [openRating, setRatingOpen] = useState(false);
  const [signupsData, setSignupsData] = useState(null);
  console.log("Signups Data", signupsData);
  const [totalSignups, setTotalSignups] = useState(0);
  const [subscriptionsData, setSubscriptionsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [activityLoad, setActivityLoad] = useState(false);
  const [feedLoad, setFeedLoad] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [latestActivity, setLatestActivity] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [litRevFeedbackData, setLitRevFeedbackData] = useState([]);
  const [ratingInfo, setRatingInfo] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [openLimitation, setOpenLimitation] = useState(false);
  const [limitationData, setLimitationData] = useState([]);
  const [totalUser, setTotalUser] = useState("");
  const [cancelSubscriptionsData, setCancelSubscriptionsData] = useState(null);
  const [signupLoad, setSignupLoad] = useState(false);
  const [subsLoad, setSubsLoad] = useState(false);
  const [cancelLoad, setCancelLoad] = useState(false);
  // const [dateRange, setDateRange] = useState(["", ""]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [subscriptionRange, setSubscriptionRange] = useState([null, null]);
  const [cancelRange, setCancelRange] = useState([null, null]);

  const [selectedOption, setSelectedOption] = useState("yearly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (selectedTab == "user") {
      handleAllUser();
    } else if (selectedTab == "feedback") {
      fetchFeedBack();
    } else if (selectedTab == "latestActivity") {
      getActivityData();
    } else if (selectedTab == "literatureReviewFeedback") {
      getLitRevFeedback();
    } else if (selectedTab == "signups" && selectedOption !== "custom") {
      fetchSignups();
    } else if (selectedTab == "subscriptions") {
      fetchSubscriptions();
    } else if (selectedTab == "cancelSubscription") {
      fetchCancelSubscriptions();
    }
  }, [selectedTab, selectedOption]);

  // const handleDateChange = (dates) => {
  //   if (dates && dates.length === 2) {
  //     setSubscriptionRange([
  //       dates[0]?.format("DD-MM-YYYY") || "",
  //       dates[1]?.format("DD-MM-YYYY") || "",
  //     ]);
  //   }
  // };
  // const handleSubscriptionChange = (dates) => {
  //   if (dates && dates.length === 2) {
  //     setSubscriptionRange([
  //       dates[0]?.format("DD-MM-YYYY") || "",
  //       dates[1]?.format("DD-MM-YYYY") || "",
  //     ]);
  //   }
  // };

  const handleSubscriptionChange = (date, position) => {
    const newDateRange = [...subscriptionRange];
    newDateRange[position] = date;
    setSubscriptionRange(newDateRange);
  };

  // const handleCancelSubscriptionChange = (dates) => {
  //   if (dates && dates.length === 2) {
  //     setCancelRange([
  //       dates[0]?.format("DD-MM-YYYY") || "",
  //       dates[1]?.format("DD-MM-YYYY") || "",
  //     ]);
  //   }
  // };

  const handleCancelSubscriptionChange = (date, position) => {
    const newDateRange = [...cancelRange];
    newDateRange[position] = date;
    setCancelRange(newDateRange);
  };

  // const clearDateRange = async () => {
  //   // setDateRange(["", ""]);
  //   setDateRange([null, null]);
  //   try {
  //     setSignupLoad(true);
  //     const response = await axios.post(`${baseDomain}/users`, {
  //       request: {
  //         method: "getTotalSignUpsByRange",
  //         data: {
  //           startDate: "",
  //           endDate: "",
  //         },
  //       },
  //     });

  //     if (response.data.response.status.statusCode === 200) {
  //       setSignupLoad(false);
  //       setSignupsData(response.data.response.data.totalSignUps);
  //     } else {
  //       console.error("Failed to fetch signups data");
  //       setSignupLoad(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching signups data:", error);
  //     setSignupLoad(false);
  //   }
  // };

  const handleDateChange = (date, position) => {
    const newDateRange = [...dateRange];
    newDateRange[position] = date;
    setDateRange(newDateRange);
  };

  const clearSubscriptionRange = async () => {
    try {
      setSubscriptionRange([null, null]);
      setSubsLoad(true); // Set the loading state to true before making the API call
      const response = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTotalSubscribersByRange",
          data: {
            startDate: "",
            endDate: "",
          },
        },
      });

      if (response.data.response.status.statusCode === 200) {
        setSubscriptionsData(response.data.response.data);
        setSubsLoad(false); // Set loading state to false after data is fetched
      } else {
        console.error("Failed to fetch subscriptions data");
        setSubsLoad(false);
      }
    } catch (error) {
      console.error("Error fetching subscriptions data:", error);
      setSubsLoad(false); // Ensure loading state is set to false on error
    }
  };

  const clearCancelSubscriptionRange = async () => {
    try {
      setCancelRange([null, null]);
      setCancelLoad(true); // Set the loading state to true before making the API call
      const response = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTotalSubscribersByRange",
          data: {
            startDate: "",
            endDate: "",
          },
        },
      });

      if (response.data.response.status.statusCode === 200) {
        setSubscriptionsData(response.data.response.data);
      } else {
        console.error("Failed to fetch subscriptions data");
      }
    } catch (error) {
      console.error("Error fetching subscriptions data:", error);
    } finally {
      setCancelLoad(false);
    }
  };

  const fetchFeedBack = async () => {
    setFeedLoad(true);
    const resp = await getFeedbackEmails();
    setFeedbackData(resp?.emailsArr);
    setFeedLoad(false);
  };

  const feedCols = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Date of Feedback",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleDateString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Feedback",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => toggle(row.email)}
        >
          Check Feedback
        </Button>
      ),
    },
  ];
  const columns = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Total Word Count",
      selector: (row) => row.wordCount || 0,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
  ];

  const actCols = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Modified Data",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleDateString();
      },
    },
    {
      name: "Time",
      cell: (row) => {
        const date = new Date(row.modified_at * 1000);
        return date.toLocaleTimeString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Limitations",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => toggleLimitation(row)}
        >
          Check Limitations
        </Button>
      ),
    },
  ];

  const litReviewFedColumns = [
    {
      name: "Index",
      selector: (row, idx) => idx + 1,
    },
    {
      name: "Date",
      cell: (row) => {
        //convert Unix timestamp to date
        const date = new Date(row.modified_at * 1000);
        return date.toDateString();
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Feedback",
      selector: (row) => (
        <Button
          size="small"
          variant="contained"
          sx={{ background: "#353535 !important", textTransform: "none" }}
          onClick={() => handleRating(row.email)}
        >
          Check Feedback
        </Button>
      ),
    },
  ];
  // const handleAllUser = async () => {
  //   try {
  //     setLoading(true);
  //     let data = await axios
  //       .post(`${baseDomain}/users`, {
  //         request: {
  //           method: "getTopUsersActivity",
  //         },
  //       })
  //       .then((res) => {
  //         axios.post(`${baseDomain}/research-projects`, {
  //           request: {
  //             method: "getWordCountByEmail",
  //             data: {
  //               emails: res.data?.response?.data?.usersArr.map(
  //                 (item) => item.email
  //               ),
  //             },
  //           },
  //         });
  //       });

  //     console.log("newData", data);
  //     // if (data.response.status.statusCode == 200) {
  //     //   let arr = data.response.data?.usersArr;

  //     //   const { newData } = await axios.post(
  //     //     `${baseDomain}/research-projects`,
  //     //     {
  //     //       request: {
  //     //         method: "getWordCountByEmail",
  //     //         data: {
  //     //           emails: arr.map((item) => item.email),
  //     //         },
  //     //       },
  //     //     }
  //     //   );
  //     //   console.log("newData", newData);
  //     //   if (newData.response.status.statusCode == 200) {
  //     //     setAllUsers(newData.response.data.researchProjects);
  //     //   }
  //     // } else {
  //     //   setAllUsers([]);
  //     // }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };
  const handleAllUser = async () => {
    try {
      setLoading(true);
      let data = await axios
        .post(`${baseDomain}/users`, {
          request: {
            method: "getTopUsersActivity",
          },
        })
        .then((res) => {
          // Return the inner axios.post call
          return axios.post(`${baseDomain}/research-projects`, {
            request: {
              method: "getWordCountByEmail",
              data: {
                emails: res.data?.response?.data?.usersArr.map(
                  (item) => item.email
                ),
              },
            },
          });
        });

      setAllUsers(data.data.response.data.researchProjects);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getActivityData = async () => {
    try {
      setActivityLoad(true);
      let { data } = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTopUsersActivity",
        },
      });

      console.log("output", data.response.data?.usersArr);
      setLatestActivity(data.response.data?.usersArr);
      setTotalUser(data.response.data?.totalUsers);
      setActivityLoad(false);
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };
  const getLitRevFeedback = async () => {
    try {
      setLoading(true);
      let { data } = await axios.post(`${baseDomain}/literature_ratings`, {
        request: {
          method: "getLiteratureRatingsEmails",
        },
      });
      if (data.response.status.statusCode == 200) {
        //sort by date
        const sorted = data.response.data.emailsArr.sort(
          (a, b) => b.modified_at - a.modified_at
        );

        setLitRevFeedbackData(sorted);
      } else {
        setLitRevFeedbackData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // const handleTabChange = (e) => {
  //   const { value } = e.target;
  //   setSelectedTab(value);
  //   if (value == "user" && allUsers.length == 0) {
  //     handleAllUser();
  //   } else if (value == "feedback" && feedbackData.length == 0) {
  //     fetchFeedBack();
  //   } else if (value == "latestActivity" && latestActivity.length == 0) {
  //     getActivityData();
  //   } else if (
  //     value == "literatureReviewFeedback" &&
  //     litRevFeedbackData.length == 0
  //   ) {
  //     getLitRevFeedback();
  //   }
  // };

  // const fetchSignups = async () => {
  //   try {
  //     setSignupLoad(true);
  //     const response = await axios.post(`${baseDomain}/users`, {
  //       request: {
  //         method: "getTotalSignUpsByRange",
  //         data: {
  //           // startDate: dateRange[0].format("DD-MM-YYYY"),
  //           // endDate: dateRange[1].format("DD-MM-YYYY"),
  //           startDate: dateRange[0] ? format(dateRange[0], "dd-MM-yyyy") : "",
  //           endDate: dateRange[1] ? format(dateRange[1], "dd-MM-yyyy") : "",
  //         },
  //       },
  //     });

  //     if (response.data.response.status.statusCode === 200) {
  //       setSignupLoad(false);
  //       setSignupsData(response.data.response.data.totalSignUps);
  //     } else {
  //       console.error("Failed to fetch signups data");
  //       setSignupLoad(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching signups data:", error);
  //     setSignupLoad(false);
  //   }
  // };

  const fetchSignups = async () => {
    try {
      setSignupLoad(true);

      // let data = { timeRange: selectedOption };

      // if (selectedOption === "yearly") {
      //   data.year = selectedYear;
      // } else if (selectedOption === "custom") {
      //   data.startDate = dateRange[0] ? format(dateRange[0], "dd-MM-yyyy") : "";
      //   data.endDate = dateRange[1] ? format(dateRange[1], "dd-MM-yyyy") : "";
      // }
      let data = {};

      if (selectedOption === "yearly") {
        data = {
          timeRange: selectedOption,
          year: selectedYear,
        };
      } else if (selectedOption === "custom") {
        data = {
          startDate: dateRange[0] ? format(dateRange[0], "dd-MM-yyyy") : "",
          endDate: dateRange[1] ? format(dateRange[1], "dd-MM-yyyy") : "",
        };
      } else {
        data = {
          timeRange: selectedOption,
        };
      }

      const response = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTotalSignUpsByRange",
          data: data,
        },
      });

      if (response.data.response.status.statusCode === 200) {
        const { totalSignUps, signUpsArr } = response.data.response.data;
        setTotalSignups(totalSignUps);

        let processedData = [];
        if (selectedOption === "yearly") {
          processedData = signUpsArr.map(({ signUps, month }) => ({
            date: month,
            count: signUps,
          }));
        } else if (selectedOption === "weekly") {
          // Reverse the array before processing
          const reversedData = [...signUpsArr].reverse(); // Create a copy and reverse it

          processedData = reversedData.map(({ signUps, day }) => ({
            date: day,
            count: signUps,
          }));
        } else if (selectedOption === "today") {
          processedData = signUpsArr.map(({ hour, signUpsData }) => {
            // Convert 24-hour format to 12-hour format with A.M. / P.M.
            const period = hour < 12 ? "A.M." : "P.M.";
            const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
            return {
              date: `${formattedHour} ${period}`, // Format the date with A.M. / P.M.
              count: signUpsData,
            };
          });
        } else if (selectedOption === "custom") {
          processedData = signUpsArr.map(({ signUps, date }) => ({
            date,
            count: signUps,
          }));
        }

        setSignupsData(processedData);
        setSignupLoad(false);
      } else {
        console.error("Failed to fetch signups data");
        setSignupLoad(false);
      }
    } catch (error) {
      console.error("Error fetching signups data:", error);
      setSignupLoad(false);
    }
  };

  const clearDateRange = async () => {
    setDateRange([null, null]); // Clear the date range

    // try {
    //   setSignupLoad(true);

    //   const response = await axios.post(`${baseDomain}/users`, {
    //     request: {
    //       method: "getTotalSignUpsByRange",
    //       data: {
    //         timeRange: "custom", // Default to custom with no date range
    //         startDate: "",
    //         endDate: "",
    //       },
    //     },
    //   });

    //   if (response.data.response.status.statusCode === 200) {
    //     setSignupLoad(false);
    //     setSignupsData(response.data.response.data.totalSignUps);
    //   } else {
    //     console.error("Failed to fetch signups data");
    //     setSignupLoad(false);
    //   }
    // } catch (error) {
    //   console.error("Error fetching signups data:", error);
    //   setSignupLoad(false);
    // }
  };

  const fetchSubscriptions = async () => {
    try {
      setSubsLoad(true);
      const response = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTotalSubscribersByRange",
          data: {
            // startDate: "",
            // endDate: "",
            // startDate: subscriptionRange[0] || "",
            // endDate: subscriptionRange[1] || "",
            startDate: subscriptionRange[0]
              ? format(subscriptionRange[0], "dd-MM-yyyy")
              : "",
            endDate: subscriptionRange[1]
              ? format(subscriptionRange[1], "dd-MM-yyyy")
              : "",
          },
        },
      });

      if (response.data.response.status.statusCode === 200) {
        setSubscriptionsData(response.data.response.data);
        setSubsLoad(false);
      } else {
        console.error("Failed to fetch subscriptions data");
        setSubsLoad(false);
      }
    } catch (error) {
      console.error("Error fetching subscriptions data:", error);
      setSubsLoad(false);
    }
  };

  const fetchCancelSubscriptions = async () => {
    try {
      setCancelLoad(true);
      const response = await axios.post(`${baseDomain}/users`, {
        request: {
          method: "getTotalCancelledSubscriptionsByRange",
          data: {
            // startDate: "",
            // endDate: "",
            // startDate: cancelRange[0] || "",
            // endDate: cancelRange[1] || "",
            startDate: cancelRange[0]
              ? format(cancelRange[0], "dd-MM-yyyy")
              : "",
            endDate: cancelRange[1] ? format(cancelRange[1], "dd-MM-yyyy") : "",
          },
        },
      });

      if (response.data.response.status.statusCode === 200) {
        setCancelSubscriptionsData(response.data.response.data);
      }
    } catch (error) {
      console.error("Error fetching cancelled subscriptions data:", error);
    } finally {
      setCancelLoad(false); // Ensure loading state is set to false after the API call
    }
  };

  // const handleTabChange = (value) => {
  //   setSelectedTab(value);
  //   if (value === "user" && allUsers.length === 0) {
  //     handleAllUser();
  //   } else if (value === "feedback" && feedbackData.length === 0) {
  //     fetchFeedBack();
  //   } else if (value === "latestActivity" && latestActivity.length === 0) {
  //     getActivityData();
  //   } else if (
  //     value === "literatureReviewFeedback" &&
  //     litRevFeedbackData.length === 0
  //   ) {
  //     getLitRevFeedback();
  //   } else if (value === "signups" && !signupsData) {
  //     fetchSignups();
  //   } else if (value === "subscriptions" && !subscriptionsData) {
  //     fetchSubscriptions();
  //   } else if (value === "cancelSubscription" && !cancelSubscriptionsData) {
  //     fetchCancelSubscriptions();
  //   }
  // };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    if (value === "user" && allUsers.length === 0) {
      handleAllUser();
    } else if (value === "feedback" && feedbackData.length === 0) {
      fetchFeedBack();
    } else if (value === "latestActivity" && latestActivity.length === 0) {
      getActivityData();
    } else if (
      value === "literatureReviewFeedback" &&
      litRevFeedbackData.length === 0
    ) {
      getLitRevFeedback();
    } else if (value === "signups" && !signupsData) {
      fetchSignups();
    } else if (value === "subscriptions" && !subscriptionsData) {
      fetchSubscriptions();
    } else if (value === "cancelSubscription" && !cancelSubscriptionsData) {
      fetchCancelSubscriptions();
    }
  };

  const toggle = (row = null) => {
    setOpen((prev) => !prev);
    setUserInfo(row);
  };

  const toggleLimitation = (limitation) => {
    setLimitationData(limitation);
    setOpenLimitation(true);
  };
  const handleRating = (row = null) => {
    setRatingOpen((prev) => !prev);
    setRatingInfo(row);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = () => {
    if (selectedTab == "user") {
      if (searchTerm) {
        let filtered = allUsers?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return allUsers;
      }
    } else if (selectedTab == "feedback") {
      if (searchTerm) {
        let filtered = feedbackData?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return feedbackData;
      }
    } else if (selectedTab == "literatureReviewFeedback") {
      if (searchTerm) {
        let filtered = litRevFeedbackData?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return litRevFeedbackData;
      }
    } else {
      if (searchTerm) {
        let filtered = latestActivity?.filter((user) =>
          user?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return filtered;
      } else {
        return latestActivity;
      }
    }
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      let { data } = await Repo.excel({
        request: {
          method: "createAllEmailsExcel",
        },
      });
      setDownloading(false);
      if (data.response.data.success) {
        window.open(data.response.data.success);
      } else {
        Toast("info", data.response.status.statusMessage);
      }
    } catch (err) {
      console.log("err:", err);
      setDownloading(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    navigate("/");
  };
  const handleClose = () => {
    setOpenLimitation(false);
  };

  const columnsSubs = [
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      render: (text) => (
        <Typography variant="h6" style={{ color: "#ffffff" }}>
          {text}
        </Typography>
      ),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      render: (text) => (
        <Typography
          variant="h6"
          style={{
            color: "#1e88e5",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {text || 0}
        </Typography>
      ),
    },
  ];

  return (
    <Box sx={{ p: "1%" }}>
      <Box
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Box></Box> */}
        {/* <Box
          sx={{
            maxWidth: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            // padding: "0px 10px",
          }}
        >
          <Segmented
            options={[
              { label: "Users", value: "user" },
              { label: "Signups", value: "signups" },
              { label: "Subscriptions", value: "subscriptions" },
              { label: "Cancelled Subscriptions", value: "cancelSubscription" },
              { label: "Feedbacks", value: "feedback" },
              { label: "Latest Activity", value: "latestActivity" },
              {
                label: "Literature Review Feedbacks",
                value: "literatureReviewFeedback",
              },
            ]}
            value={selectedTab}
            onChange={handleTabChange}
            style={{ padding: "6px 0px", fontSize: "18px" }}
          />
        </Box> */}

        <Box
          sx={{
            maxWidth: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              padding: "6px 0px",
              "& .MuiTab-root": { fontSize: "18px", textTransform: "none" },
            }}
          >
            <Tab label="Users" value="user" />
            <Tab label="Signups" value="signups" />
            <Tab label="Subscriptions" value="subscriptions" />
            <Tab label="Cancelled Subscriptions" value="cancelSubscription" />
            <Tab label="Feedbacks" value="feedback" />
            <Tab label="Latest Activity" value="latestActivity" />
            <Tab
              label="Literature Review Feedbacks"
              value="literatureReviewFeedback"
            />
          </Tabs>
        </Box>

        {/* <Typography variant="h6" sx={{ textAlign: "center" }}>
          Registered Users
        </Typography> */}
        <Button
          variant="contained"
          size="small"
          sx={{
            background: "#353535 !important",
            textTransform: "none",
            px: 4,
            py: 1,
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>

      <CBBox
        sx={{
          my: 1,
          flexWrap: "wrap",
          gap: 2,
          justifyContent: { xs: "center", sm: "space-between" },
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={handleSearch}
          size="small"
        />
        <Stack direction="row" spacing="8px" flexWrap={"wrap"}>
          {totalUser && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginInline: "20px",
              }}
            >
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {totalUser && `Total Users: ${totalUser}`}
              </Typography>
            </Box>
          )}
          {!loading && (
            <Box sx={{ minWidth: "80px" }}>
              {downloading && (
                <TailSpin
                  height="36"
                  width="36"
                  color="#353535"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              )}
              {!downloading && (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={handleDownload}
                >
                  Download Emails
                </Button>
              )}
            </Box>
          )}

          {/* <FormControl>
            <RadioGroup row value={selectedTab} onChange={handleTabChange}>
              <FormControlLabel
                value="user"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Users"
              />
              <FormControlLabel
                value="feedback"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Feedbacks"
              />
              <FormControlLabel
                value="latestActivity"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Latest Activity"
              />
              <FormControlLabel
                value="literatureReviewFeedback"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#4E4E4E",
                      },
                    }}
                    disableRipple
                  />
                }
                label="Literature Review Feedbacks"
              />
            </RadioGroup>
          </FormControl> */}
        </Stack>
      </CBBox>
      {selectedTab == "user" && (
        <Box>
          {loading && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!loading && (
            <DataTable
              columns={columns}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "feedback" && (
        <Box>
          {feedLoad && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!feedLoad && (
            <DataTable
              columns={feedCols}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "latestActivity" && (
        <Box>
          {activityLoad && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!activityLoad && (
            <DataTable
              columns={actCols}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}
      {selectedTab == "literatureReviewFeedback" && (
        <Box>
          {loading && (
            <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={50} sx={{ color: "#353535" }} />
            </Box>
          )}

          {!loading && (
            <DataTable
              columns={litReviewFedColumns}
              data={filteredUsers()}
              customStyles={customStyles}
              pagination
            />
          )}
        </Box>
      )}

      <Box>
        {selectedTab === "signups" && (
          <Box>
            {/* <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >

              <Box
                sx={{
                  padding: "6px",
                  border: "1px solid #d3d3d3",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  fontSize: "16px",
                  color: "#333",
                  width: "200px",
                  outline: "none",

                  "& input:focus": {
                    backgroundColor: "#f9f9f9",
                    border: "0px solid #f9f9f9",
                    outline: "none",
                  },
                }}
              >
                <DatePicker
                  selected={dateRange[0]}
                  onChange={(date) => handleDateChange(date, 0)} // Update start date
                  selectsStart
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  placeholderText="Select Start Date"
                  dateFormat="dd-MM-yyyy"
                />
              </Box>


              <Box
                sx={{
                  padding: "6px",
                  border: "1px solid #d3d3d3",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  fontSize: "16px",
                  color: "#333",
                  width: "200px",
                  outline: "none",

                  "& input:focus": {
                    backgroundColor: "#f9f9f9",
                    border: "0px solid #f9f9f9",
                    outline: "none",
                  },
                }}
              >
                <DatePicker
                  selected={dateRange[1]}
                  onChange={(date) => handleDateChange(date, 1)} // Update end date
                  selectsEnd
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  minDate={dateRange[0] ? new Date(dateRange[0]) : null}
                  placeholderText="Select End Date"
                  dateFormat="dd-MM-yyyy"
                />
              </Box>

              <Button
                variant="contained"
                onClick={fetchSignups}
                disabled={!dateRange[0] || !dateRange[1]}
                sx={{
                  background: "#353535",
                  "&:hover": { background: "#353535" },
                }}
              >
                Fetch Signups
              </Button>

              <Button
                onClick={clearDateRange}
                variant="contained"
                sx={{
                  background: "#ff4d4f",
                  color: "#fff",
                  px: 4,
                  "&:hover": { background: "#ff4d4f" },
                }}
              >
                Clear
              </Button>
            </Box> */}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "1rem",
                mt: 2,
              }}
            >
              <FormControl size="small">
                <InputLabel
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  Select Date Range
                </InputLabel>
                <Select
                  value={selectedOption}
                  label="Select Date Ran"
                  size="small"
                  onChange={(e) => setSelectedOption(e.target.value)}
                  displayEmpty
                  sx={{
                    // padding: "6px",
                    minWidth: "150px",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    // border: "1px solid #d3d3d3",
                    fontSize: "16px",
                    color: "#333",
                  }}
                >
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>

              {selectedOption === "yearly" && (
                <FormControl size="small">
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    Select Year
                  </InputLabel>
                  <Select
                    value={selectedYear}
                    label="Select Ye"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    size="small"
                    sx={{
                      minWidth: "150px",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      fontSize: "16px",
                      color: "#333",
                    }}
                  >
                    {Array.from(
                      { length: new Date().getFullYear() - 2023 },
                      (_, i) => (
                        <MenuItem key={i} value={2024 + i}>
                          {2024 + i}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

              {selectedOption === "custom" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      padding: "6px",
                      border: "1px solid #d3d3d3",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      fontSize: "16px",
                      color: "#333",
                      width: "200px",
                      outline: "none",
                    }}
                  >
                    <DatePicker
                      selected={dateRange[0]}
                      onChange={(date) => handleDateChange(date, 0)} // Update start date
                      selectsStart
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      placeholderText="Select Start Date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </Box>

                  {/* End Date Picker */}
                  <Box
                    sx={{
                      padding: "6px",
                      border: "1px solid #d3d3d3",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      fontSize: "16px",
                      color: "#333",
                      width: "200px",
                      outline: "none",
                    }}
                  >
                    <DatePicker
                      selected={dateRange[1]}
                      onChange={(date) => handleDateChange(date, 1)} // Update end date
                      selectsEnd
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      minDate={dateRange[0] ? new Date(dateRange[0]) : null}
                      placeholderText="Select End Date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </Box>

                  <Button
                    onClick={clearDateRange}
                    variant="contained"
                    sx={{
                      background: "#ff4d4f",
                      color: "#fff",
                      px: 4,
                      "&:hover": { background: "#ff4d4f" },
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              )}

              {/* Action Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={fetchSignups}
                  disabled={
                    selectedOption === "custom" &&
                    (!dateRange[0] || !dateRange[1])
                  }
                  sx={{
                    background: "#353535",
                    "&:hover": { background: "#353535" },
                  }}
                >
                  Fetch Signups
                </Button>
              </Box>
            </Box>

            {signupLoad ? (
              <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
                <CircularProgress size={50} sx={{ color: "#353535" }} />
              </Box>
            ) : (
              <>
                <Box sx={{ marginTop: "1rem" }}>
                  <Card
                    sx={{
                      maxWidth: "300px",
                      py: 1,
                      margin: "0 auto",
                      backgroundColor: "#e3f2fd",
                      borderRadius: "12px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* <CardContent> */}
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#1565c0",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "8px",
                      }}
                    >
                      Total Signups
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#1e88e5",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {totalSignups || 0}
                    </Typography>
                    {/* </CardContent> */}
                  </Card>
                </Box>
                <Box sx={{ width: "100%", height: "300px", marginTop: "1rem" }}>
                  {/* {selectedOption === "custom" ? (
                    <SignupCustomChart signupsData={signupsData || []} />
                  ) : ( */}
                  <SignupChart signupsData={signupsData || []} />
                  {/* )} */}
                </Box>
              </>
            )}
          </Box>
        )}

        {selectedTab === "subscriptions" && subscriptionsData !== null && (
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                mb: 3,
              }}
            >
              {/* <DatePicker.RangePicker
                onChange={handleSubscriptionChange}
                value={
                  subscriptionRange[0] && subscriptionRange[1]
                    ? [
                        moment(subscriptionRange[0], "DD-MM-YYYY"),
                        moment(subscriptionRange[1], "DD-MM-YYYY"),
                      ]
                    : null
                }
                format="DD-MM-YYYY"
                style={{
                  width: "30%",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              /> */}

              <Box
                sx={{
                  padding: "6px",
                  border: "1px solid #d3d3d3",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  fontSize: "16px",
                  color: "#333",
                  width: "200px",
                  outline: "none",

                  "& input:focus": {
                    backgroundColor: "#f9f9f9",
                    border: "0px solid #f9f9f9",
                    outline: "none",
                  },
                }}
              >
                <DatePicker
                  selected={subscriptionRange[0]}
                  onChange={(date) => handleSubscriptionChange(date, 0)} // Update start date
                  selectsStart
                  startDate={subscriptionRange[0]}
                  endDate={subscriptionRange[1]}
                  placeholderText="Select Start Date"
                  dateFormat="dd-MM-yyyy"
                />
              </Box>

              {/* End Date Picker */}

              <Box
                sx={{
                  padding: "6px",
                  border: "1px solid #d3d3d3",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  fontSize: "16px",
                  color: "#333",
                  width: "200px",
                  outline: "none",

                  "& input:focus": {
                    backgroundColor: "#f9f9f9",
                    border: "0px solid #f9f9f9",
                    outline: "none",
                  },
                }}
              >
                <DatePicker
                  selected={subscriptionRange[1]}
                  onChange={(date) => handleSubscriptionChange(date, 1)} // Update end date
                  selectsEnd
                  startDate={subscriptionRange[0]}
                  endDate={subscriptionRange[1]}
                  minDate={
                    subscriptionRange[0] ? new Date(subscriptionRange[0]) : null
                  }
                  placeholderText="Select End Date"
                  dateFormat="dd-MM-yyyy"
                />
              </Box>

              <Button
                variant="contained"
                onClick={fetchSubscriptions}
                disabled={!subscriptionRange[0] || !subscriptionRange[1]}
                sx={{
                  background: "#353535",
                  "&:hover": { background: "#353535" },
                }}
              >
                Fetch Subscriptions
              </Button>

              {/* Clear Button */}
              <Button
                onClick={clearSubscriptionRange}
                variant="contained"
                sx={{
                  background: "#ff4d4f",
                  color: "#fff",
                  px: 4,
                  "&:hover": { background: "#ff4d4f" },
                }}
              >
                Clear
              </Button>
            </Box>

            {subsLoad ? (
              <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
                <CircularProgress size={50} sx={{ color: "#353535" }} />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="subscriptions table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#01A35F" }}>
                      <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                        Subscription Type
                      </TableCell>
                      <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Total Subscribers
                      </TableCell>
                      <TableCell>
                        {subscriptionsData?.totalSubscribers}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Standard Monthly Users
                      </TableCell>
                      <TableCell>
                        {subscriptionsData?.totalStandardMonthlyUsers}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Standard Yearly Users
                      </TableCell>
                      <TableCell>
                        {subscriptionsData?.totalStandardYearlyUsers}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Pro Monthly Users
                      </TableCell>
                      <TableCell>
                        {subscriptionsData?.totalProMonthlyUsers}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Pro Yearly Users
                      </TableCell>
                      <TableCell>
                        {subscriptionsData?.totalProYearlyUsers}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}

        {selectedTab === "cancelSubscription" &&
          cancelSubscriptionsData !== null && (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  mb: 3,
                }}
              >
                {/* <DatePicker.RangePicker
                  onChange={handleCancelSubscriptionChange}
                  value={
                    cancelRange[0] && cancelRange[1]
                      ? [
                          moment(cancelRange[0], "DD-MM-YYYY"),
                          moment(cancelRange[1], "DD-MM-YYYY"),
                        ]
                      : null
                  }
                  format="DD-MM-YYYY"
                  style={{
                    width: "30%",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                /> */}

                <Box
                  sx={{
                    padding: "6px",
                    border: "1px solid #d3d3d3",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "16px",
                    color: "#333",
                    width: "200px",
                    outline: "none",

                    "& input:focus": {
                      backgroundColor: "#f9f9f9",
                      border: "0px solid #f9f9f9",
                      outline: "none",
                    },
                  }}
                >
                  <DatePicker
                    selected={cancelRange[0]}
                    onChange={(date) => handleCancelSubscriptionChange(date, 0)} // Update start date
                    selectsStart
                    startDate={cancelRange[0]}
                    endDate={cancelRange[1]}
                    placeholderText="Select Start Date"
                    dateFormat="dd-MM-yyyy"
                  />
                </Box>

                {/* End Date Picker */}

                <Box
                  sx={{
                    padding: "6px",
                    border: "1px solid #d3d3d3",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "16px",
                    color: "#333",
                    width: "200px",
                    outline: "none",

                    "& input:focus": {
                      backgroundColor: "#f9f9f9",
                      border: "0px solid #f9f9f9",
                      outline: "none",
                    },
                  }}
                >
                  <DatePicker
                    selected={cancelRange[1]}
                    onChange={(date) => handleCancelSubscriptionChange(date, 1)} // Update end date
                    selectsEnd
                    startDate={cancelRange[0]}
                    endDate={cancelRange[1]}
                    minDate={cancelRange[0] ? new Date(cancelRange[0]) : null}
                    placeholderText="Select End Date"
                    dateFormat="dd-MM-yyyy"
                  />
                </Box>

                <Button
                  variant="contained"
                  onClick={fetchCancelSubscriptions}
                  disabled={!cancelRange[0] || !cancelRange[1]}
                  sx={{
                    background: "#353535",
                    "&:hover": { background: "#353535" },
                  }}
                >
                  Fetch Canceled Subscriptions
                </Button>

                {/* Clear Button */}
                <Button
                  onClick={clearCancelSubscriptionRange}
                  variant="contained"
                  sx={{
                    background: "#ff4d4f",
                    color: "#fff",
                    px: 4,
                    "&:hover": { background: "#ff4d4f" },
                  }}
                >
                  Clear
                </Button>
              </Box>

              {cancelLoad ? (
                <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={50} sx={{ color: "#353535" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  {/* Total Cancelled Subscriptions */}
                  <Typography variant="body1" sx={{ mb: 2, fontSize: "20px" }}>
                    Total Cancelled Subscriptions:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#01A35F",
                      }}
                    >
                      {cancelSubscriptionsData.totalCancelledSubscriptions}
                    </span>
                  </Typography>

                  {/* Divider for styling */}
                  <Divider sx={{ mt: 2 }} />

                  {/* Table for Cancelled Subscribers */}
                  <TableContainer
                    component={Paper}
                    sx={{ maxWidth: "100%", width: "100%" }}
                  >
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="cancelled subscriptions table"
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#01A35F" }}>
                          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                            Email
                          </TableCell>
                          <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                            Plan
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(
                          cancelSubscriptionsData.cancelledSubscribers
                        ).length > 0 ? (
                          Object.keys(
                            cancelSubscriptionsData.cancelledSubscribers
                          ).map((email, index) => (
                            <TableRow key={index}>
                              <TableCell>{email}</TableCell>
                              <TableCell>
                                {
                                  cancelSubscriptionsData.cancelledSubscribers[
                                    email
                                  ]
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                              No cancelled subscriptions
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          )}
      </Box>

      {open && <FeedBacks info={userInfo} onClose={toggle} />}
      {openRating && !open && (
        <FeedBacks ratingInfo={ratingInfo} onClose={handleRating} rating />
      )}
      {latestActivity && openLimitation && (
        <Limitations
          limitation={limitationData?.limitations}
          open={openLimitation}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};

export default Protal;
