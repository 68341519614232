import React, { useEffect, useState } from "react";
import {
  Typography,
  TextareaAutosize,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CircularWithValueLabel from "./CircularProgressWithLabel";
import EssayGuidelineDialog from "./EssayGuidelineDialog";

const NewEssay = ({
  topic,
  handleTopicChange,
  essayType,
  handleEssayTypeChange,
  wordCount,
  progress,
  setProgress,
  handleWordCountChange,
  includeHeading,
  handleIncludeHeadingChange,
  isSmall,
  isLoading,
  handleStartWriting,
  errors,
  setErrors,
  user,
  setIsLoading,
  maxCharacters,
  maxCharactersDesc,
  handleDescriptionChange,
  description,
}) => {
  const MenuProps = {
    PaperProps: {
      sx: {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Set your desired box shadow here
      },
    },
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleToggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  // useEffect(() => {
  //   let progressInterval;
  //   if (isLoading) {
  //     progressInterval = setInterval(() => {
  //       setProgress((prev) => {
  //         if (prev >= 100) {
  //           setIsLoading(false); // Stop loading when progress reaches 100%
  //           return 0; // Reset progress to 0%
  //         }
  //         return prev + 1;
  //       });
  //     }, 100); // Adjust the interval for faster/slower progress update
  //   }
  //   return () => {
  //     clearInterval(progressInterval); // Cleanup on component unmount
  //   };
  // }, [isLoading]);

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontFamily: "Raleway", fontWeight: 600 }}
      >
        What's your Essay About?
      </Typography>
      {/* <TextareaAutosize
        minRows={2}
        placeholder="Write your Essay Title"
        value={topic}
        // onChange={handleTopicChange}
        // onChange={(e) => {
        //   handleTopicChange(e);
        //   if (errors.topic) setErrors({ ...errors, topic: "" }); // Clear error on input
        // }}
        onChange={handleTopicChange}
        style={{
          width: isSmall ? "100%" : "80%",
          padding: "12px",
          fontSize: "16px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          outline: "none",
          resize: "none",
          overflowY: "auto",
        }}
        onFocus={(e) => {
          e.target.style.border = "1px solid #ccc";
        }}
        onBlur={(e) => {
          e.target.style.border = "1px solid #ccc";
        }}
      />
      {errors.topic && (
        <small
          style={{
            color: "red",
            marginTop: "-14px",
            width: isSmall ? "100%" : "80%",
          }}
        >
          {errors.topic}
        </small>
      )}
      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
          fontSize: "14px",
          color: "#666",
        }}
      >
        {maxCharacters - topic.length} characters remaining
      </Typography> */}

      <div
        style={{
          position: "relative",
          width: isSmall ? "100%" : "80%",
          margin: "0 auto",
        }}
      >
        <TextareaAutosize
          minRows={2}
          placeholder="Write your Essay Title"
          value={topic}
          onChange={handleTopicChange}
          style={{
            width: "100%",
            padding: "12px",
            fontSize: "16px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            outline: "none",
            resize: "none",
            maxHeight: "72px",
            overflowY: "auto",
          }}
          onFocus={(e) => {
            e.target.style.border = "1px solid #ccc";
          }}
          onBlur={(e) => {
            e.target.style.border = "1px solid #ccc";
          }}
        />
        {errors.topic && (
          <small
            style={{
              color: "red",
              // marginTop: "4px",
              display: "block",
            }}
          >
            {errors.topic}
          </small>
        )}
        <span
          style={{
            position: "absolute",
            bottom: errors.topic ? "34px" : "14px",
            right: "12px",
            fontSize: "12px",
            color: "#666",
          }}
        >
          {maxCharacters - (topic?.length || 0)}/100 char
        </span>
      </div>

      <div
        style={{
          position: "relative",
          width: isSmall ? "100%" : "80%",
          margin: "0 auto",
        }}
      >
        <TextareaAutosize
          minRows={4}
          placeholder="Briefly describe your Essay (optional)"
          value={description}
          onChange={handleDescriptionChange}
          style={{
            width: "100%", // Matches FormControl width
            padding: "12px",
            fontSize: "16px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            outline: "none",
            resize: "none",
            maxHeight: "120px",
            overflowY: "auto",
          }}
          onFocus={(e) => {
            e.target.style.border = "1px solid #ccc";
          }}
          onBlur={(e) => {
            e.target.style.border = "1px solid #ccc";
          }}
        />
        {errors.description && (
          <small
            style={{
              color: "red",
              marginTop: "4px",
              display: "block",
            }}
          >
            {errors.description}
          </small>
        )}
        <span
          style={{
            position: "absolute",
            bottom: "14px",
            right: "12px",
            fontSize: "12px",
            color: "#666",
          }}
        >
          {maxCharactersDesc - (description?.length || 0)}/500 char
        </span>
      </div>

      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        sx={{
          maxWidth: { xs: "100%", sm: "80%" },
          width: "100%",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <FormControl
          fullWidth
          size={isSmall ? "small" : "large"}
          sx={{
            flex: { xs: "1 1 100%", sm: "1 1 calc(50% - 8px)" },
            // background: "#FFFFFF",
          }}
        >
          <InputLabel>Essay Type</InputLabel>
          <Select
            value={essayType}
            // onChange={handleEssayTypeChange}
            MenuProps={MenuProps}
            sx={{ background: "#FFFFFF" }}
            onChange={(e) => {
              handleEssayTypeChange(e);
              if (errors.essayType) setErrors({ ...errors, essayType: "" }); // Clear error on input
            }}
            label="Essay Type"
          >
            <MenuItem value="Basic">Basic</MenuItem>
            <MenuItem value="Descriptive">Descriptive</MenuItem>
            <MenuItem value="Narrative">Narrative</MenuItem>
            <MenuItem value="Persuasive">Persuasive</MenuItem>
            <MenuItem value="Expository">Expository</MenuItem>
          </Select>
          {errors.essayType && (
            <small style={{ color: "red", marginTop: "4px" }}>
              {errors.essayType}
            </small>
          )}
        </FormControl>

        <FormControl
          fullWidth
          size={isSmall ? "small" : "large"}
          sx={{
            flex: { xs: "1 1 100%", sm: "1 1 calc(50% - 8px)" },
          }}
        >
          <InputLabel>Words</InputLabel>
          <Select
            value={wordCount}
            MenuProps={MenuProps}
            sx={{ background: "#FFFFFF" }}
            onChange={(e) => {
              handleWordCountChange(e);
              if (errors.wordCount) setErrors({ ...errors, wordCount: "" }); // Clear error on input
            }}
            label="Words"
          >
            {/* Free and available to all */}
            <MenuItem value="100-200" disabled={false}>
              100-200
            </MenuItem>
            <MenuItem value="200-500" disabled={false}>
              200-500
            </MenuItem>

            {/* Standard Plan */}
            <MenuItem
              value="500-800"
              disabled={user?.subscription?.planName === "free-plan"}
            >
              500-800{" "}
              {user?.subscription?.planName === "free-plan" && "(standard)"}
            </MenuItem>
            <MenuItem
              value="800-1000"
              disabled={user?.subscription?.planName === "free-plan"}
            >
              800-1000{" "}
              {user?.subscription?.planName === "free-plan" && "(standard)"}
            </MenuItem>
            <MenuItem
              value="1000-1200"
              disabled={user?.subscription?.planName === "free-plan"}
            >
              1000-1200{" "}
              {user?.subscription?.planName === "free-plan" && "(standard)"}
            </MenuItem>

            {/* Pro Plan */}
            <MenuItem
              value="1200-1500"
              disabled={user?.subscription?.planName !== "pro"}
            >
              1200-1500 {user?.subscription?.planName !== "pro" && "(pro-plan)"}
            </MenuItem>
            <MenuItem
              value="1500-2000"
              disabled={user?.subscription?.planName !== "pro"}
            >
              1500-2000 {user?.subscription?.planName !== "pro" && "(pro-plan)"}
            </MenuItem>
            <MenuItem
              value="2000-2500"
              disabled={user?.subscription?.planName !== "pro"}
            >
              2000-2500 {user?.subscription?.planName !== "pro" && "(pro-plan)"}
            </MenuItem>
          </Select>

          {errors.wordCount && (
            <small style={{ color: "red", marginTop: "4px" }}>
              {errors.wordCount}
            </small>
          )}
        </FormControl>
      </Stack>

      <Stack
        direction={"row"}
        sx={{ width: isSmall ? "100%" : "80%" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        gap={1}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Switch
              checked={includeHeading}
              onChange={handleIncludeHeadingChange}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#163C54",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#163C54",
                },
                "& .MuiSwitch-switchBase": {
                  color: "#EFEFEF",
                },
              }}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontWeight: "500",
                fontSize: "16px",
                color: "#163C54",
              }}
            >
              Include Headings
            </Typography>
          }
        />
        <Button
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "auto" },
            textTransform: "capitalize",
            background: "#1E4F6B",
            boxShadow: "none",
            "&:hover": {
              background: "#163C54",
            },
            fontFamily: "Raleway",
          }}
          onClick={handleToggleDialog}
        >
          See Guidelines
        </Button>
      </Stack>
      <EssayGuidelineDialog open={openDialog} onClose={handleToggleDialog} />

      <Button
        variant="contained"
        size="large"
        onClick={handleStartWriting}
        startIcon={
          isLoading ? (
            // <CircularProgress size={24} sx={{ color: "white" }} />
            <CircularWithValueLabel value={progress} />
          ) : (
            <AutoAwesomeIcon />
          )
        }
        sx={{
          px: isLoading ? 3 : 8,
          py: isLoading ? 0.5 : 1,
          width: isSmall ? "100%" : "auto",
          background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
          "&:hover": {
            background: "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
          },
          textTransform: "unset",
        }}
      >
        {isLoading ? "Writing essay, please wait!..." : "Start Writing"}
      </Button>
    </>
  );
};

export default NewEssay;
