import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
// import Files from "../../assets/images/file.png";
import { InsertDriveFile } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  InputAdornment,
  ListItemIcon,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  disconnectZotero,
  getAuthorizationURL,
  getDocumentData,
  saveEmbed,
  saveMultipleFiles,
  sendErrorMail,
} from "../../apiservice";
import SearchHistory from "../../assets/images/search-history.svg";
import Logo from "../../assets/images/zotero-logo.png";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import { baseDomain } from "../../repo/Repository";
import { addZoteroKey } from "../Admin/userSlice";
import { setZoteroLoading } from "../loader/LoaderSlice";
import { clearRefs } from "../references/refsSlice";
import ZoteroAccordion from "./ZoteroAccordion";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ZoteroDialogue = (props) => {
  const { onClose, open, getProject, id, zKey } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const [state, setState] = useState({
    userId: "",
    search: "",
    searchData: [],
    blobObj: {},
    data: {},
    loading: false,
    btn: false,
    folders: false,
    subFolderData: [],
    currentPage: 1,
    itemsPerPage: 30,
  });

  console.log("State", state);

  const isSmall = useMediaQuery("(max-width:480px)");
  const [loading, setLoading] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [checked, setChecked] = useState([]);
  const [s3Files, setS3Files] = useState([]);
  const { pathname } = useLocation();
  const [itemType, setItemType] = useState("");
  const [folderData, setFolderData] = useState([]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    setState((prev) => ({
      ...prev,
      searchData: [],
    }));
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (zKey) {
      token();
    }
  }, [zKey]);

  const token = async () => {
    try {
      const urlParams = new URLSearchParams(zKey);
      const oauth_token = urlParams.get("oauth_token");
      if (oauth_token) {
        const oauth_verifier = urlParams.get("oauth_verifier");
        setLoading(true);
        let { data } = await axios.post(`${baseDomain}/zotero`, {
          request: {
            method: "getAccessToken",
            data: {
              oauthToken: oauth_token,
              oauthTokenSecret: localStorage.getItem("oAuthTokenSecret"),
              oauthVerifier: oauth_verifier,
              email: localStorage.getItem("email"),
            },
          },
        });
        setLoading(false);
        if (data.response.data.accessToken) {
          localStorage.setItem("zoteroKey", data.response.data.accessToken);
          localStorage.setItem("userId", data.response.data.userId);
          dispatch(addZoteroKey(data.response.data.accessToken));
          navigate(`${pathname}`);
          // navigate(`${pathname}`, { state: { zotero: true } });
        } else {
          // Toast("error", data.response.status.statusMessage);
          Toast("error", "Something went wrong, try again");
          onClose();
        }
      }
    } catch (err) {
      console.log("err:", err);
      Toast("error", "Something went wrong, try again");
      setLoading(false);
      onClose();
    }
  };

  const handleToggle = (value) => () => {
    setChecked((prevChecked) => {
      const currentIndex = prevChecked.indexOf(value);
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        if (newChecked.length < 5) {
          newChecked.push(value);
        } else {
          Toast("warning", "You can only select up to 5 files.");
        }
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });
  };

  // const getProjects = async () => {
  //   let newInfo = {
  //     email: localStorage?.getItem("email"),
  //     title: localStorage?.getItem("projectTitle"),
  //   };
  //   let res = await getDocumentData(newInfo);

  //   let found = {};
  //   if (res) {
  //     found = res.data.researchProjects;
  //     console.log("Found researchProjects:", found);
  //     if (found) {
  //       setS3Files(found.s3Files);
  //       console.log("S3 Files set:", found.s3Files);
  //     }
  //   }

  //   return found?.s3Files;
  // };

  // const searchItems = async (start = 0, limit = 100) => {
  //   try {
  //     let id = localStorage.getItem("userId");
  //     let key = localStorage.getItem("zoteroKey");
  //     setChecked([]);
  //     setState((prev) => ({
  //       ...prev,
  //       loading: true,
  //       searchData: [],
  //       folders: false,
  //     }));
  //     let s3 = await getProjects();
  //     console.log("S3 GetProjects", s3);
  //     let newData = [];
  //     const firstResponse = await axios.get(
  //       // `https://api.zotero.org/users/${id}/items/top?limit=100`,
  //       `https://api.zotero.org/users/${id}/items/top?limit=${limit}&start=${start}`,
  //       {
  //         headers: {
  //           "Zotero-API-Key": key,
  //         },
  //       }
  //     );
  //     console.log("First Response", firstResponse);
  //     const secondResponse = await axios.get(
  //       // `https://api.zotero.org/users/${id}/items/top?limit=100`,
  //       `https://api.zotero.org/users/${id}/items/top?limit=${200}&start=${101}`,
  //       {
  //         headers: {
  //           "Zotero-API-Key": key,
  //         },
  //       }
  //     );
  //     console.log("Second Response", secondResponse);
  //     if (firstResponse && secondResponse) {
  //       let filtered = firstResponse.data
  //         .concat(secondResponse.data)
  //         .filter((item) => {
  //           const email = localStorage.getItem("email");
  //           const title = item.data.title;
  //           const pattern = new RegExp(`${email}/[0-9]${title}`);
  //           return s3.some((file) => pattern.test(file));
  //         });
  //       console.log("Combined Filtered", filtered);
  //       filtered.forEach((object) => {
  //         object.alreadyAdded = true;
  //       });
  //       console.log("FIltererd Already", filtered);
  //       filtered.map((obj) => {
  //         firstResponse.data.concat(secondResponse.data).map((item) => {
  //           if (obj.key === item.key) {
  //             item = { ...obj, ...item };
  //           }
  //           return item;
  //         });
  //         return obj;
  //       });
  //       let filteredData = firstResponse.data.concat(secondResponse.data);
  //       //remove duplicates items from the array
  //       let unique = filteredData.filter(
  //         (v, i, a) => a.findIndex((t) => t.key === v.key) === i
  //       );

  //       setState((prev) => ({
  //         ...prev,
  //         searchData: unique,
  //         loading: false,
  //       }));
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     Toast("error", err);
  //     setState((prev) => ({
  //       ...prev,
  //       loading: false,
  //     }));
  //   }
  // };

  const getProjects = async () => {
    let newInfo = {
      email: localStorage?.getItem("email"),
      title: localStorage?.getItem("projectTitle"),
    };

    let res = await getDocumentData(newInfo);

    let found = {};
    if (res) {
      found = res.data.researchProjects;
      console.log("Found researchProjects:", found);
      if (found) {
        setS3Files(found.s3FileReferences); // Updated to use s3FileReferences
        console.log("S3 File References set:", found.s3FileReferences);
      }
    }

    return found?.s3FileReferences; // Updated to return s3FileReferences
  };

  const searchItems = async (start = 0, limit = 100) => {
    try {
      let id = localStorage.getItem("userId");
      let key = localStorage.getItem("zoteroKey");
      setChecked([]);
      setState((prev) => ({
        ...prev,
        loading: true,
        searchData: [],
        folders: false,
      }));

      // Get the updated s3FileReferences
      let s3FileReferences = await getProjects();
      console.log("S3 File References from GetProjects", s3FileReferences);

      // Convert s3FileReferences to an array of keys
      const s3FilesArray = Object.keys(s3FileReferences || {});
      console.log("Converted S3 File References to Array", s3FilesArray);

      const normalizedTitle = (str) =>
        str.trim().toLowerCase().replace(/\s+/g, " ");

      const normalizedS3Files = s3FilesArray.map((file) =>
        normalizedTitle(file)
      );

      let newData = [];
      const firstResponse = await axios.get(
        `https://api.zotero.org/users/${id}/items/top?limit=${limit}&start=${start}`,
        {
          headers: {
            "Zotero-API-Key": key,
          },
        }
      );
      console.log("First Response", firstResponse);

      const secondResponse = await axios.get(
        `https://api.zotero.org/users/${id}/items/top?limit=${200}&start=${101}`,
        {
          headers: {
            "Zotero-API-Key": key,
          },
        }
      );
      console.log("Second Response", secondResponse);

      if (firstResponse && secondResponse) {
        let filtered = firstResponse.data
          .concat(secondResponse.data)
          .filter((item) => {
            const email = localStorage.getItem("email");
            const title = item.data.title;
            // const pattern = new RegExp(`${email}/[0-9]${title}`);
            const pattern = new RegExp(`${email}/.*${title}`, "i");
            // return s3FilesArray.some((file) => pattern.test(file)); // Use the array here
            return normalizedS3Files.some((file) => pattern.test(file));
          });

        console.log("Combined Filtered", filtered);
        filtered.forEach((object) => {
          object.alreadyAdded = true;
        });

        // let filteredData = firstResponse.data.concat(secondResponse.data);
        // // Remove duplicates items from the array
        // let unique = filteredData.filter(
        //   (v, i, a) => a.findIndex((t) => t.key === v.key) === i
        // );

        const unique = Array.from(
          new Set(
            [...firstResponse.data, ...secondResponse.data].map(
              (item) => item.key
            )
          )
        ).map((key) =>
          [...firstResponse.data, ...secondResponse.data].find(
            (item) => item.key === key
          )
        );

        setState((prev) => ({
          ...prev,
          searchData: unique,
          loading: false,
        }));
      }
    } catch (err) {
      console.log("err:", err);
      Toast("error", err);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  //   const searchItems = async (start = 0, limit = 100) => {
  //   try {
  //     let id = localStorage.getItem("userId");
  //     let key = localStorage.getItem("zoteroKey");
  //     setChecked([]);
  //     setState((prev) => ({
  //       ...prev,
  //       loading: true,
  //       searchData: [],
  //       folders: false,
  //     }));

  //     let s3 = await getProjects(); // List of already uploaded files
  //     const email = localStorage.getItem("email");

  //     // Fetch data from Zotero API
  //     const firstResponse = await axios.get(
  //       `https://api.zotero.org/users/${id}/items/top?limit=${limit}&start=${start}`,
  //       { headers: { "Zotero-API-Key": key } }
  //     );
  //     const secondResponse = await axios.get(
  //       `https://api.zotero.org/users/${id}/items/top?limit=${200}&start=${101}`,
  //       { headers: { "Zotero-API-Key": key } }
  //     );

  //     if (firstResponse && secondResponse) {
  //       let allData = firstResponse.data.concat(secondResponse.data);

  //       // Add `alreadyAdded` flag
  //       allData = allData.map((item) => {
  //         const title = item.data.title;
  //         const pattern = new RegExp(`${email}/[0-9]+${title}`);
  //         const alreadyAdded = s3.some((file) => pattern.test(file));
  //         return { ...item, alreadyAdded };
  //       });

  //       // Remove duplicates
  //       const uniqueData = allData.filter(
  //         (v, i, a) => a.findIndex((t) => t.key === v.key) === i
  //       );

  //       setState((prev) => ({
  //         ...prev,
  //         searchData: uniqueData,
  //         loading: false,
  //       }));
  //     }
  //   } catch (err) {
  //     console.error("Error:", err);
  //     Toast("error", err.message);
  //     setState((prev) => ({
  //       ...prev,
  //       loading: false,
  //     }));
  //   }
  // };

  const selectAll = (e, data) => {
    const { checked } = e.target;
    if (checked) {
      setChecked(data);
    } else {
      setChecked([]);
    }
  };
  let key = localStorage.getItem("zoteroKey");
  // const searchFolders = async () => {
  //   let key = localStorage.getItem("zoteroKey");
  //   setChecked([]);

  //   setState((prev) => ({
  //     ...prev,
  //     loading: true,
  //     searchData: [],
  //     folders: true,
  //   }));
  //   let id = localStorage.getItem("userId");
  //   axios
  //     .get(`https://api.zotero.org/users/${id}/collections`, {
  //       headers: {
  //         "Zotero-API-Key": key,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("collection:", response)
  //       if (response.status === 200) {
  //           if(response.data)
  //           {

  //             axios
  //             .get(`https://api.zotero.org/users/${id}/collections/${key}/collections`, {
  //               headers: {
  //                 "Zotero-API-Key": key,
  //               },
  //             })
  //             .then((response) => {

  //               if (response.status === 200) {
  //                 setState((prev) => ({
  //                   ...prev,
  //                   subFolderData: response.data,
  //                   // loading: false,
  //                 }));
  //               } else {
  //                 console.error("Error:", response.status, response.statusText);
  //               }
  //             })
  //           }

  //         setState((prev) => ({
  //           ...prev,
  //           searchData: response.data,
  //           loading: false,
  //         }));
  //       } else {
  //         console.error("Error:", response.status, response.statusText);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("API Request Error:", error);
  //     });
  // };
  const searchFolders = async () => {
    let key = localStorage.getItem("zoteroKey");
    setChecked([]);

    setState((prev) => ({
      ...prev,
      loading: true,
      searchData: [],
      folders: true,
    }));
    let id = localStorage.getItem("userId");
    let s3 = await getProjects();
    if (typeof s3 === "object" && s3 !== null) {
      s3 = Object.keys(s3); // Extract keys from the object
    } else {
      console.error("s3 is not an object. Received:", s3);
      s3 = [];
    }
    axios
      .get(`https://api.zotero.org/users/${id}/collections`, {
        headers: {
          "Zotero-API-Key": key,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            let parent = [];
            let child = [];
            let array = JSON.parse(JSON.stringify(response.data));

            array.forEach((i) => {
              if (i.data.parentCollection == false) {
                parent.push(i.data);
              } else {
                child.push(i.data);
              }
            });

            if (child.length > 0) {
              child.forEach((item) => {
                parent.forEach((parentItem) => {
                  if (item.parentCollection === parentItem.key) {
                    // parentItem.subFolderData = [];
                    if (!parentItem.subFolderData) {
                      parentItem.subFolderData = [];
                    }

                    parentItem.subFolderData.push(item);
                  }
                });
              });
            }
            const normalizedS3 = s3.map((file) => file.toLowerCase());

            let filtered = parent.filter((item) => {
              const email = localStorage.getItem("email");
              // const title = item.name;
              const title = item.name.toLowerCase();
              // const pattern = new RegExp(`${email}/[0-9]${title}`);
              const pattern = new RegExp(`${email}/[0-9]${title}`, "i");
              // return s3.some((file) => pattern.test(file));
              return normalizedS3.some((file) => pattern.test(file));
            });
            console.log("filtered:", filtered);
            filtered.forEach((object) => {
              object.alreadyAdded = true;
            });

            filtered.map((obj) => {
              response.data.map((item) => {
                if (obj.key === item.key) {
                  item = { ...obj, ...item };
                }
                return item;
              });
              return obj;
            });

            setFolderData(parent);
            setState((prev) => ({
              ...prev,
              searchData: parent,
              loading: false,
            }));
          } else {
            console.error("Error:", response.status, response.statusText);
          }
        } else {
          console.error("Error:", response.status, response.statusText);
          Toast("error", "No collections found");
        }
      })
      .catch((error) => {
        console.error("API Request Error:", error);
      });
  };

  const handleChange = (e) => {
    let { name } = e.target;

    setState((prev) => ({ ...prev, [name]: e.target.value }));
  };
  const initiateOAuth = async () => {
    setLoading(true);
    let resp = await getAuthorizationURL({ url: location.pathname });
    if (resp) {
      localStorage.setItem("oAuthTokenSecret", resp.oAuthTokenSecret);
      window.location.href = `${resp.authorizationUrl}`;
    }
  };
  // const viewFile = async (value) => {
  //   if (value.links.enclosure) {
  //     setState((prev) => ({ ...prev, loading: true }));
  //     try {
  //       // Make a GET request to your Java API endpoint with the PDF URL
  //       const response = await axios.get(
  //         `${baseDomain}/fetchPdf?pdfUrl=${
  //           value.links.enclosure.href
  //         }&token=${localStorage.getItem("zoteroKey")}`,
  //         {
  //           // Add any required headers for your Java API here
  //           responseType: "blob", // Set the response type to 'blob' for PDF files
  //         }
  //       );
  //       const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //       setState((prev) => ({ ...prev, blobObj: pdfBlob, data: value }));

  //       const pdfUrlBlob = URL.createObjectURL(pdfBlob);
  //       setCurrentPdfUrl(pdfUrlBlob);
  //       setState((prev) => ({ ...prev, loading: false }));
  //     } catch (error) {
  //       toast.error("Error opening PDF via API");
  //     }
  //   } else {
  //     toast.error("You cannot open a file that is not in PDF format.");
  //   }
  // };
  const clearPdf = () => {
    setCurrentPdfUrl("");
  };
  const saveMultipleFile = async () => {
    const timeoutId = setTimeout(() => {
      sendErrorMail({
        user: localStorage.getItem("email"),
        feature: "Zotero File Upload",
        error: "20 seconds",
        query: "",
        delayInResponse: true,
      });
    }, 20000);

    setState((prev) => ({ ...prev, btn: true }));
    let obj = {};
    checked.forEach((item) => {
      if (item?.links?.enclosure) {
        let title = item.data.title;
        if (title) {
          obj[title] = item.links?.enclosure?.href;
        }
      } else if (item?.links?.attachment) {
        let title =
          item?.links?.attachment.attachmentType === "application/pdf"
            ? `${item.data.title}.pdf`
            : item.data.title;
        if (title) {
          obj[title] = `${item.links?.attachment?.href}/file/view`;
        }
      } else if (item?.links?.self) {
        let title = `${item.data.title}`;
        if (title) {
          obj[title] = `${item.links?.self?.href}`;
        }
      }
    });
    console.log("obj:", obj);
    let datas = {
      email: localStorage.getItem("email"),
      app: "zotero",
      projectId: localStorage.getItem("projectId"),
      urls: obj,
    };

    if (Object.keys(obj).length !== 0) {
      let res = await saveMultipleFiles(datas);
      clearTimeout(timeoutId);
      if (res.error) {
        Toast("error", res.error);
        setState((prev) => ({ ...prev, btn: false }));
        if (
          res.error ===
          "Upload papers limit reached. Please upgrade your subscription for more."
        ) {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Zotero File Upload",
            error: res.error,
            query: "",
            limitReachedError: true,
          });
        } else {
          sendErrorMail({
            user: localStorage.getItem("email"),
            feature: "Zotero File Upload",
            error: res.error,
            query: "",
          });
        }

        return;
      }
      if (res.fileError || res.filesSaved) {
        if (res.fileError) {
          Toast("warning", res.fileError);
          if (res.fileError.includes("Upload papers limit reached")) {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Zotero File Upload",
              error: res.fileError,
              query: "",
              limitReachedError: true,
            });
          } else {
            sendErrorMail({
              user: localStorage.getItem("email"),
              feature: "Zotero File Upload",
              error: res.fileError,
              query: "",
            });
          }
        }
        if (res.filesSaved.length > 0) {
          const saveEmbedInfo = {
            saveEmbeddings: true,
            email: localStorage.getItem("email"),
            title: localStorage.getItem("projectTitle"),
            s3KeysArr: res.filesSaved,
          };
          saveEmbed(saveEmbedInfo);
          saveRef(res.filesSaved);
          setState((prev) => ({ ...prev, btn: false }));
          Toast(
            "info",
            "Please wait while we add relevant reference for this file in references section"
          );
          getProject();
          onClose();
        } else {
          Toast("error", "No saved files found");
        }
      }
    } else {
      Toast("info", "Metadata file(s) format are not supported");
      setState((prev) => ({ ...prev, btn: false }));
    }
  };

  const saveRef = async (arr) => {
    //get all ch.ecked files
    try {
      setState((prev) => ({ ...prev, loading: true }));
      dispatch(setZoteroLoading(true));
      let obj = {
        getCitationsAgainstMultiplePapers: true,
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        s3KeysArr: arr,
      };
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(obj),
        redirect: "follow",
      };
      fetch(`${baseDomain}/script`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          let parsed = JSON.parse(result);
          dispatch(setZoteroLoading(false));
          if (parsed?.data?.sourceObj?.error) {
            Toast("error", parsed.data.sourceObj.error);
          }
          const reference = parsed?.data?.sourceObj.responseArr.map((item) => {
            return item;
          });
          //convert reference to object from array
          const refObj = {};
          reference.forEach((item) => {
            refObj[`${item.title}`] = item;
          });
          dispatch(clearRefs());

          if (parsed?.data?.sourceObj?.responseArr) {
            let req = {
              researchProjectId: id,
              references: refObj,
            };

            // await saveResearchReferences(req);
            dispatch(clearRefs());
            getProject();
            setState((prev) => ({ ...prev, loading: false }));
            // dispatch(setZoteroLoading(false));
          }
          getProject();

          setState((prev) => ({ ...prev, loading: false }));
        });
    } catch (err) {
      console.log("err:", err);
      Toast("error", err);
    }
  };
  const disconZotero = async () => {
    const email = localStorage.getItem("email");
    try {
      let res = await disconnectZotero(email);
      if (res) {
        localStorage.removeItem("userId");
        localStorage.removeItem("zoteroKey");
        localStorage.removeItem("oAuthTokenSecret");
        onClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchChange = (e) => {
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const filteredData = () => {
    if (state.search) {
      let filtered = state.searchData?.filter((item) =>
        item?.data?.title?.toLowerCase()?.includes(state.search?.toLowerCase())
      );
      return filtered;
    } else {
      return state.searchData;
    }
  };
  const getSlicedData = () => {
    const filtered = filteredData();
    console.log("filtered:", filtered);
    const startIndex = (state.currentPage - 1) * state.itemsPerPage;
    const endIndex = startIndex + state.itemsPerPage;
    return filteredData().slice(startIndex, endIndex);
  };

  console.log("Get Sliced Data", getSlicedData());

  const handlePageChange = (event, value) => {
    setState((prev) => ({
      ...prev,
      currentPage: value,
    }));
    if (state.currentPage >= 10) {
      searchItems(100, 11);
    }
  };
  const numPages = Math.ceil(filteredData().length / state.itemsPerPage);

  const handleFetchItemsClick = async () => {
    await searchItems(0, 100);
  };

  return (
    <Dialog onClose={onClose} open={open || zKey} fullWidth>
      <DialogTitle>
        {localStorage.getItem("userId") !== "undefined" &&
          localStorage.getItem("userId") && (
            <Stack
              sx={{
                dispaly: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              direction="row"
            >
              <img src={Logo} alt="" />
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  // background:
                  //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  // "&:hover": {
                  //   background:
                  //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  // },
                  background: "#E85757",
                  "&:hover": {
                    background: "#CC4C4C",
                  },
                  fontFamily: "Raleway",
                  fontSize: "10px",
                  fontWeight: 500,
                  height: "24px !important",
                  color: "#fff !important",
                  padding: "6px 12px !important",
                }}
                onClick={disconZotero}
              >
                Disconnect
              </Button>
            </Stack>
          )}
        {(localStorage.getItem("userId") === "undefined" ||
          !localStorage.getItem("userId")) && (
          <Stack justifyContent="center" alignItems="center">
            <img src={Logo} alt="" />
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        {localStorage.getItem("userId") == "undefined" ||
        !localStorage.getItem("userId") ? (
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <h1>Connect your account to start importing your files</h1>

            {!loading && (
              <Button
                onClick={initiateOAuth}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  background:
                    "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  },
                }}
              >
                Connect
              </Button>
            )}
            {loading && <TailSpinLoader />}
          </Stack>
        ) : (
          <>
            {!currentPdfUrl && (
              <Stack alignItems="center">
                <Stack
                  direction="row"
                  columnGap={2}
                  flexWrap={"wrap"}
                  justifyContent={{ xs: "center", sm: "unset" }}
                >
                  {state.loading && <TailSpinLoader />}
                  {!state.loading && (
                    <Button
                      onClick={() => handleFetchItemsClick()}
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        // background:
                        //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                        // "&:hover": {
                        //   background:
                        //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                        // },
                        background: "#4A90E2",
                        "&:hover": {
                          background: "#3B7AE1",
                        },
                        mt: 2,
                      }}
                    >
                      Fetch Papers From Library
                    </Button>
                  )}
                  {!state.loading && (
                    <Button
                      onClick={searchFolders}
                      variant="contained"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        // background:
                        //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                        // "&:hover": {
                        //   background:
                        //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                        // },
                        background: "#1AB394",
                        "&:hover": {
                          background: "#159A78",
                        },
                        mt: 2,
                        width: "181px",
                      }}
                    >
                      Fetch Collections
                    </Button>
                  )}
                </Stack>
              </Stack>
            )}
            {currentPdfUrl ? (
              <div>
                <Button onClick={clearPdf}>
                  <ArrowBackIcon />
                </Button>
                <Document file={currentPdfUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            ) : (
              <List>
                {state.folders ? (
                  <ZoteroAccordion
                    data={state.searchData}
                    subFolderData={state.subFolderData}
                    s3Files={s3Files}
                    toggle={(data) => handleToggle(data)}
                    checked={checked}
                    selectAll={(e, data) => selectAll(e, data)}
                    getProject={getProject}
                    zoteroClose={onClose}
                  />
                ) : (
                  <>
                    {state.searchData.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <TextField
                          size="small"
                          variant="outlined"
                          placeholder="Search files ..."
                          value={state.search}
                          id="search"
                          // label="Search"
                          name="search"
                          onChange={handleSearchChange}
                          sx={{
                            width: "60%",
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Raleway",
                              fontWeight: 600,
                              fontSize: "12px",
                              color: "#353535",
                              borderRadius: "6px",
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon
                                  sx={{ fontSize: "16px", color: "#BDBDBD" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    {getSlicedData() &&
                      getSlicedData().map((value, id) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;

                        return (
                          <ListItem key={value} disablePadding>
                            {
                              //when i click on next page and previous page the index should be updated accordingly

                              (state.currentPage - 1) * state.itemsPerPage +
                                id +
                                1
                            }

                            <ListItemButton
                              disabled={value.alreadyAdded}
                              onClick={handleToggle(value)}
                            >
                              {/* <ListItemIcon> */}
                              <Checkbox
                                size="small"
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disabled={checked[0]}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "#353535",
                                  },
                                }}
                              />
                              {/* </ListItemIcon> */}
                              {/* <ListItemAvatar> */}
                              <Box sx={{ mr: 1 }}>
                                <InsertDriveFile />
                              </Box>

                              {/* </ListItemAvatar> */}
                              <ListItemText
                                // onClick={() => viewFile(value)}
                                id={labelId}
                                primary={
                                  value.data?.title?.length >
                                  (isSmall ? 20 : 30)
                                    ? `${value.data?.title.substring(
                                        0,
                                        isSmall ? 20 : 30
                                      )}...`
                                    : value.data?.title
                                }
                                primaryTypographyProps={{
                                  style: {
                                    fontSize: isSmall ? "14px" : "16px",
                                  }, // Set font size to 12px
                                }}
                                // primary={`${value.data.title}`}
                              />
                              {value.alreadyAdded && (
                                <Chip
                                  label="Uploaded"
                                  color="success"
                                  sx={{
                                    height: "20px",
                                    borderRadius: "5px !important",
                                  }}
                                />
                              )}
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                  </>
                )}
              </List>
            )}
            {getSlicedData().length === 0 && state.searchData.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "40vh",
                }}
              >
                <img
                  src={SearchHistory}
                  alt=""
                  styles={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#4E4E4E",
                    my: 1,
                  }}
                >
                  No Search Results
                </Typography>
              </Box>
            )}
          </>
        )}
        {numPages > 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Stack spacing={2} direction="row">
              <Pagination
                count={numPages}
                page={state.currentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {checked &&
          checked.length !== 0 &&
          !currentPdfUrl &&
          (state.btn ? (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#FFFFFF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"14px"} sx={{ color: "#FFFFFF" }}>
                Uploading ...
              </Typography>
            </Stack>
          ) : (
            <Button
              variant="contained"
              disabled={state.btn}
              sx={{
                textTransform: "capitalize",
                color: state.btn ? "#FFFFFF" : "#FFFFFF",
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
              }}
              onClick={saveMultipleFile}
            >
              Import
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
};
