import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../pages/Admin/userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, FLUSH, REHYDRATE } from "redux-persist";
import { PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import insightsSlice from "../pages/paper insights/insightsSlice";
import reviewSlice from "../pages/literature review/reviewSlice";
import projectSlice from "../pages/projects/projectSlice";
import sidebarSlice from "../pages/sidebar/toggleSlice";
import loaderSlice from "../pages/loader/LoaderSlice";
import editorSlice from "../pages/editorSlice";
import refsSlice from "../pages/references/refsSlice";
import paperSlice from "../pages/papers/paperSlice";
import semanticPaperSlice from "../pages/papers/semanticPaperSlice";
import routesReducer from "../pages/papers/routeSlice";
import BlogSlice from "../pages/landing page/blog/BlogSlice";
import essaySlice from "../pages/projects/essay/essaySlice";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ['insights'],
};

const semanticPersistConfig = {
  key: "semanticPaper",
  storage,
};

const blogPersistConfig = {
  key: "blogs",
  storage,
};

const persistedReducer = persistReducer(persistConfig, insightsSlice);
const reviewPersisted = persistReducer(persistConfig, reviewSlice);
const essayPersisted = persistReducer(persistConfig, essaySlice);
const userPersisted = persistReducer(persistConfig, userSlice);
// const semanticePersisted = persistReducer(persistConfig, semanticPaperSlice);
const semanticPersisted = persistReducer(
  semanticPersistConfig,
  semanticPaperSlice
);
const blogPersisted = persistReducer(blogPersistConfig, BlogSlice);

export const store = configureStore({
  reducer: {
    user: userPersisted,
    editor: editorSlice,
    review: reviewPersisted,
    loader: loaderSlice,
    refs: refsSlice,
    papers: paperSlice,
    // semanticPaper:semanticePersisted,
    semanticPaper: semanticPersisted,
    insights: persistedReducer,
    sidebar: sidebarSlice,
    project: persistReducer(persistConfig, projectSlice),
    routes: routesReducer,
    blogs: blogPersisted,
    essay: essayPersisted,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
