import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import EssayImg from "../../../assets/images/essay-img.png";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Repo from "../../../repo/Repo";
import { getUser } from "../../Admin/userSlice";
import Toast from "../../../components/Toast";
import { BounceLoader } from "react-spinners";
import { setStop } from "./essaySlice";

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  color: "#353535",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

const GetStarted = () => {
  const isSmall = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stop = useSelector((state) => state.essay.stop);
  console.log("Stop", stop);

  const handleGetStarted = async () => {
    const email = localStorage.getItem("email");
    const payload = {
      request: {
        method: "addNewLimits",
        data: {
          _id: email,
        },
      },
    };
    setLoading(true);

    try {
      // const response = await Repo.user(payload);
      // console.log("Response", response);
      // if (response?.data?.response?.status?.statusCode === 200) {
      //   dispatch(setStop(true));
      //   if (response?.data?.response?.data?.essay) {
      //     setTimeout(() => {
      //       Toast(
      //         "success",
      //         `You got ${response?.data?.response?.data?.essay} Essays`
      //       );
      //     }, 1000);
      //   }
      const response = await Repo.user(payload);
      console.log("Response", response);
      if (response?.data?.response?.status?.statusCode === 200) {
        dispatch(setStop(true));

        const essayValue = response?.data?.response?.data?.essay;

        if (essayValue) {
          let essayMessage;
          if (essayValue === 1) {
            essayMessage = "Essay";
          } else if (essayValue === 0) {
            essayMessage = "No Essay";
          } else {
            essayMessage = "Essays";
          }

          setTimeout(() => {
            Toast("success", `You got ${essayValue} ${essayMessage}`);
          }, 1000);
        }

        dispatch(getUser());
      } else {
        console.error("Failed to add new limits:", response);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        height: "90vh",
        width: "100%",
        padding: { xs: "16px", sm: "0" },
      }}
    >
      <Stack
        component={motion.div}
        initial={stop ? {} : { opacity: 0, y: 50 }}
        animate={stop ? {} : { opacity: 1, y: 0 }}
        transition={stop ? {} : { duration: 1 }}
        direction="column"
        gap={3}
        sx={{
          alignItems: "center",
          textAlign: "center",
          maxWidth: { xs: "100%", md: "80%" },
          mt: { xs: 8, sm: 6 },
        }}
      >
        <motion.img
          src={EssayImg}
          alt="Essay Writing"
          style={{
            width: "100%",
            maxWidth: "550px",
            borderRadius: isSmall ? "8px" : "30px",
          }}
          initial={stop ? {} : { scale: 0.8 }}
          animate={stop ? {} : { scale: 1 }}
          transition={stop ? {} : { duration: 0.8, ease: "easeOut" }}
        />
        <Text
          variant="h1"
          sx={{
            fontSize: { xs: "32px", sm: "36px", lg: "40px", fontWeight: 600 },
          }}
        >
          Start Your Essay Writing Journey
        </Text>
        <Text
          variant="body1"
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
          }}
        >
          Unleash your creativity and craft compelling essays with ease.
        </Text>
        <motion.div
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          }}
          style={{ position: "relative" }}
        >
          {loading ? (
            // Display BounceLoader instead of the button when loading is true
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <BounceLoader color="#3460C8" size={24} />
            </Box>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={handleGetStarted}
              disabled={loading}
              sx={{
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                px: { xs: 3, sm: 5 },
                py: { xs: 1, sm: 1.25 },
                width: { xs: "100%", sm: "auto" },
                borderRadius: "8px",
                fontWeight: "bold",
                fontFamily: "Raleway",
                textTransform: "uppercase",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
              }}
            >
              Get Started
            </Button>
          )}
        </motion.div>
      </Stack>
    </Box>
  );
};

export default GetStarted;
