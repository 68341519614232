import React from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

const EssayDisplay = ({
  essay,
  onCopy,
  onNew,
  tooltipOpen,
  topic,
  handlePasteEssay,
}) => {
  // Function to format essay with appropriate line breaks
  const formatEssay = (essay) => {
    // Split text into paragraphs using double line breaks
    const paragraphs = essay.split("\r\n\r\n");

    return paragraphs.map((paragraph, index) => {
      // Further split each paragraph into lines using single line breaks
      const lines = paragraph.split("\r\n");

      return (
        <Typography
          key={index}
          variant="body1"
          sx={{ fontFamily: "Raleway", marginBottom: "16px" }} // Add spacing between paragraphs
        >
          {lines.map((line, i) => (
            <span key={i}>
              {line.split(/(\*\*.*?\*\*)/).map((part, j) => {
                // Check if the part is bold (enclosed in **)
                if (/\*\*(.*?)\*\*/.test(part)) {
                  return (
                    <strong key={j} style={{ fontWeight: "bold" }}>
                      {part.replace(/\*\*/g, "")}
                    </strong>
                  );
                }
                return part; // Regular text
              })}
              {/* Insert single line break where needed */}
              {i < lines.length - 1 && <br />}
            </span>
          ))}
        </Typography>
      );
    });
  };

  console.log("Topic", topic.length);

  const isVerySmall = useMediaQuery("(max-width:380px)");

  return (
    <>
      <Stack
        direction="row"
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={"center"}
        flexWrap={"wrap-reverse"}
        gap={2}
        sx={{ width: { xs: "98%", sm: "95%" } }}
      >
        <Typography
          sx={{
            mx: 2,
            fontFamily: "Raleway",
            fontSize: { sm: "18px", md: "20px" },
            fontWeight: 600,
          }}
        >
          {topic
            ? topic.charAt(0).toUpperCase() + topic.slice(1)
            : "Write your topic here..."}
        </Typography>

        <Stack
          direction="row"
          // justifyContent={{ xs: "center", sm: "space-between" }}
          justifyContent={{ xs: "center", sm: "end" }}
          flexWrap={"wrap-reverse"}
          width={{
            sm: "100%",
            md: topic.length > 60 ? "100%" : "auto",
          }}
          gap={2}
          // sx={{ width: { xs: "98%", sm: "95%" } }}
        >
          {/* <Stack
            direction={"row"}
            gap={2}
            justifyContent={"center"}
            flexWrap={"wrap"}
            mx={2}
          > */}
          <Tooltip
            title="Essay Copied!"
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button
              variant="outlined"
              onClick={onCopy}
              startIcon={<ContentCopyIcon />}
              sx={{
                fontFamily: "Raleway",
                border: "1px solid #535D7B",
                color: "#535D7B",
                "&:hover": { border: "1px solid #1E4F6B", color: "#14224B" },
                textTransform: "capitalize",
                px: { xs: 2, sm: 3 },
                fontSize: { xs: "12px", sm: "auto" },
                width: { xs: isVerySmall ? "90%" : "auto", sm: "auto" },
              }}
            >
              Copy
            </Button>
          </Tooltip>
          {/* </Stack> */}
          <Button
            variant="contained"
            onClick={handlePasteEssay}
            startIcon={<FolderCopyIcon />}
            sx={{
              fontFamily: "Raleway",
              background: "#3535351A !important",
              color: "#1E4F6B",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", color: "#163C54" },
              textTransform: "capitalize",
              px: { xs: 2, sm: 3 },
              fontSize: { xs: "12px", sm: "auto" },
              width: { xs: isVerySmall ? "90%" : "auto", sm: "auto" },
            }}
          >
            Paste in Editor
          </Button>

          <Button
            variant="contained"
            onClick={onNew}
            startIcon={<AddCircleIcon />}
            sx={{
              fontFamily: "Raleway",
              background: "#1E4F6B",
              boxShadow: "none",
              "&:hover": { background: "#163C54", boxShadow: "none" },
              textTransform: "capitalize",
              px: { xs: 2, sm: 3 },
              fontSize: { xs: "12px", sm: "auto" },
              width: { xs: isVerySmall ? "90%" : "auto", sm: "auto" },
            }}
          >
            New Essay
          </Button>
        </Stack>
      </Stack>

      <Box
        sx={{
          background: "#fff",
          padding: 2,
          borderRadius: "8px",
          //   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          maxWidth: { xs: "98%", sm: "95%" },
          // height: { xs: "80vh", lg: "82vh", xl: "85vh" },
          height: {
            xs:
              topic.length > 30
                ? isVerySmall
                  ? "52vh"
                  : "72vh"
                : isVerySmall
                ? "60vh"
                : "74vh",
            sm: "80vh",
            lg: topic.length > 60 ? "75vh" : "82vh",
            xl: topic.length > 60 ? "75vh" : "85vh",
          },
          overflowY: "auto",
          wordWrap: "break-word",
        }}
      >
        {formatEssay(essay)}
      </Box>
    </>
  );
};

export default EssayDisplay;
