import { useCallback, useEffect, useRef, useState } from "react";
import API from "../../../lib/api";
import Toast from "../../../../../components/Toast";

// export const useUploader = ({ onUpload }) => {
//   const [loading, setLoading] = useState(false);

//   const uploadFile = useCallback(
//     async (e) => {
//       setLoading(true);
//       try {
//         const url = await API.uploadImage(e);

//         onUpload(url);
//       } catch (errPayload) {
//         const error =
//           errPayload?.response?.data?.error || "Something went wrong";
//         Toast(error, "error");
//       }
//       setLoading(false);
//     },
//     [onUpload]
//   );

//   return { loading, uploadFile };
// };

export const useUploader = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = useCallback(
    async (file) => {
      setLoading(true);
      try {
        const uploadedFiles = await API.uploadImage(file);
        if (uploadedFiles.length > 0) {
          onUpload(uploadedFiles[0]); // assuming the server returns an array of uploaded file paths
        }
      } catch (errPayload) {
        const error =
          errPayload?.response?.data?.error || "Something went wrong";
        Toast(error, "error");
      }
      setLoading(false);
    },
    [onUpload]
  );

  return { loading, uploadFile };
};

export const useFileUpload = () => {
  const fileInput = useRef(null);

  const handleUploadClick = useCallback(() => {
    fileInput.current?.click();
  }, []);

  return { ref: fileInput, handleUploadClick };
};

export const useDropZone = ({ uploader }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggedInside, setDraggedInside] = useState(false);

  useEffect(() => {
    const dragStartHandler = () => {
      setIsDragging(true);
    };

    const dragEndHandler = () => {
      setIsDragging(false);
    };

    document.body.addEventListener("dragstart", dragStartHandler);
    document.body.addEventListener("dragend", dragEndHandler);

    return () => {
      document.body.removeEventListener("dragstart", dragStartHandler);
      document.body.removeEventListener("dragend", dragEndHandler);
    };
  }, []);

  const onDrop = useCallback(
    (e) => {
      setDraggedInside(false);
      if (e.dataTransfer.files.length === 0) {
        return;
      }

      const fileList = e.dataTransfer.files;

      const files = [];

      for (let i = 0; i < fileList.length; i += 1) {
        const item = fileList.item(i);
        if (item) {
          files.push(item);
        }
      }

      if (files.some((file) => file.type.indexOf("image") === -1)) {
        return;
      }

      e.preventDefault();

      const filteredFiles = files.filter((f) => f.type.indexOf("image") !== -1);

      const file = filteredFiles.length > 0 ? filteredFiles[0] : undefined;

      if (file) {
        uploader(file);
      }
    },
    [uploader]
  );

  const onDragEnter = () => {
    setDraggedInside(true);
  };

  const onDragLeave = () => {
    setDraggedInside(false);
  };

  return { isDragging, draggedInside, onDragEnter, onDragLeave, onDrop };
};
