import { blog1 } from "./blog1";
import { blog10 } from "./blog10";
import { blog11 } from "./blog11";
import { blog12 } from "./blog12";
import { blog13 } from "./blog13";
import { blog14 } from "./blog14";
import { blog15 } from "./blog15";
import { blog16 } from "./blog16";
import { blog17 } from "./blog17";
import { blog18 } from "./blog18";
import { blog19 } from "./blog19";
import { blog2 } from "./blog2";
import { blog20 } from "./blog20";
import { blog21 } from "./blog21";
import { blog3 } from "./blog3";
import { blog4 } from "./blog4";
import { blog5 } from "./blog5";
import { blog6 } from "./blog6";
import { blog7 } from "./blog7";
import { blog8 } from "./blog8";
import { blog9 } from "./blog9";

export const blogPosts = [
  blog21,
  blog20,
  blog19,
  blog18,
  blog17,
  blog16,
  blog15,
  blog14,
  blog13,
  blog12,
  blog11,
  blog10,
  blog9,
  blog8,
  blog7,
  blog6,
  blog5,
  blog4,
  blog3,
  blog2,
  blog1,
];
