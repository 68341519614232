import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { Oval, TailSpin } from "react-loader-spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Files from "../../assets/images/file.png";
import { pdfjs } from "react-pdf";
import {
  Button,
  Stack,
  DialogActions,
  ListItemIcon,
  Checkbox,
  Chip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import {
  getMendeleyAuthorizationURL,
  getMendeleyFiles,
  getResearch,
  saveEmbed,
  saveMultipleFiles,
  saveResearchReferences,
} from "../../apiservice";
import Logo from "../../assets/images/Mendeley_Logo_Vertical.png";
import { clearRefs } from "../references/refsSlice";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { baseDomain } from "../../repo/Repository";
import { InsertDriveFile } from "@mui/icons-material";
import TailSpinLoader from "../../components/TailSpinLoader";
import { setMendelyLoading } from "../loader/LoaderSlice";
import Toast from "../../components/Toast";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const MendeleyDialogue = ({ onClose, id, getProject }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    userId: "",
    search: "",
    searchData: [],
    blobObj: {},
    data: {},
    loading: false,
    btn: false,
    folders: false,
  });
  console.log("State searchData", state?.searchData);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch();

  const handleToggle = (value) => () => {
    setChecked((prevChecked) => {
      const currentIndex = prevChecked.indexOf(value);
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      console.log("checked", newChecked);
      return newChecked;
    });
  };

  React.useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // useEffect(() => {
  //   if (zKey) {
  //     console.log("zKey:", zKey);
  //     token();
  //   }
  // }, [zKey]);

  // const token = async () => {
  //   try {
  //     const urlParams = new URLSearchParams(zKey);
  //     const oauth_token = urlParams.get("oauth_token");
  //     if (oauth_token) {
  //       const oauth_verifier = urlParams.get("oauth_verifier");
  //       let { data } = await axios.post(`${baseDomain}/zotero`, {
  //         request: {
  //           method: "getAccessToken",
  //           data: {
  //             oauthToken: oauth_token,
  //             oauthTokenSecret: localStorage.getItem("oAuthTokenSecret"),
  //             oauthVerifier: oauth_verifier,
  //             email: localStorage.getItem("email"),
  //           },
  //         },
  //       });
  //       console.log("data:", data);
  //       // let json = res.data.response.data;
  //       // let res=  await getAccessToken(data);
  //       if (data.response.data.accessToken) {
  //         dispatch(addZoteroKey(data.response.data.accessToken));
  //         localStorage.setItem("userId", data.response.data.userId);
  //         navigate(`${pathname}`, { state: { zotero: true } });
  //         // navigate("/projects");
  //         // localStorage.setItem("zoteroKey", action.payload?.zoteroKey);
  //       }
  //     }
  //   } catch (err) {
  //     console.log("err:", err);
  //     Toast("error", err);
  //   }
  // };

  const getProjects = async () => {
    let info = {
      email: localStorage.getItem("email"),
    };
    let res = await getResearch(info);
    let found = {};
    if (res.researchProjects) {
      found = res.researchProjects.find((item) => id === item._id);
      if (found) {
        // setS3Files(found.s3Files);
      }
    }
    return found?.s3Files;
  };
  const searchItems = async () => {
    setChecked([]);
    let s3 = await getProjects();
    console.log(s3);
    setState((prev) => ({
      ...prev,
      loading: true,
      searchData: [],
      folders: false,
    }));
    let obj = {
      email: localStorage.getItem("email"),
    };
    const response = await getMendeleyFiles(obj);
    if (response) {
      let filtered = response.files.filter((item) => {
        const email = localStorage.getItem("email");
        const title = item.file_name;
        const pattern = new RegExp(`${email}/[0-9]${title}`);
        return s3.some((file) => pattern.test(file));
      });
      filtered.forEach((object) => {
        object.alreadyAdded = true;
      });
      filtered.map((obj) => {
        response.files.map((item) => {
          if (obj.key === item.key) {
            item = { ...obj, ...item };
          }
          return item;
        });
        return obj;
      });
      setState((prev) => ({
        ...prev,
        searchData: response.files,
        loading: false,
      }));
    }
  };
  const selectAll = (e, data) => {
    const { checked } = e.target;
    if (checked) {
      setChecked(data);
    } else {
      setChecked([]);
    }
  };
  const initiateOAuth = async () => {
    setLoading(true);
    let resp = await getMendeleyAuthorizationURL();
    setLoading(false);
    if (resp) {
      window.location.href = `${resp.authorizationUrl}`;
    }
  };
  const viewFile = async (value) => {
    setState((prev) => ({ ...prev, loading: true }));
    if (value) {
      let email = localStorage.getItem("email");
      fetch(
        `${baseDomain}/fetchMendeleyPdf?pdfUrl=${value.fileUrl}&email=${email}`
      ).then((res) => setCurrentPdfUrl(res.url));
      setState((prev) => ({ ...prev, loading: false }));
    } else {
      toast.error("You cannot open a file that is not in PDF format.");
    }
  };
  const clearPdf = () => {
    setCurrentPdfUrl("");
  };
  const saveMultipleFile = async () => {
    setState((prev) => ({ ...prev, btn: true }));
    let obj = {};
    checked.map((item, id) => (obj[`${item.file_name}`] = item.fileUrl));
    let datas = {
      email: localStorage.getItem("email"),
      app: "mendeley", // "zotero"  "mendeley"
      projectId: id,

      urls: obj,
    };
    let res = await saveMultipleFiles(datas);
    if (res.error) {
      toast.error(res.error);
      return;
    }
    if (res.filesSaved) {
      const saveEmbedInfo = {
        saveEmbeddings: true,
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        s3KeysArr: res.filesSaved,
      };
      saveEmbed(saveEmbedInfo);
      saveRef(res.filesSaved);
      setState((prev) => ({ ...prev, btn: false }));
      searchItems();
      toast.success("File uploaded successfully");
      getProject();
      toast.info(
        "Please wait while we add relevant reference for this file in references section"
      );
      onClose();
    }
  };
  const saveRef = async (arr) => {
    //apply

    setState((prev) => ({ ...prev, loading: true }));
    dispatch(setMendelyLoading(true));
    let obj = {
      s3KeysArr: arr,
      getCitationsAgainstMultiplePapers: true,
      email: localStorage.getItem("email"),
      title: localStorage.getItem("projectTitle"),
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
      redirect: "follow",
    };
    fetch(`${baseDomain}/script`, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        let parsed = JSON.parse(result);
        dispatch(clearRefs());
        if (parsed?.data?.sourceObj?.error) {
          Toast("error", parsed.data.sourceObj.error);
        }
        if (parsed?.data?.sourceObj.responseArr) {
          let req = {
            researchProjectId: localStorage.getItem("projectId"),
            references: parsed.data.sourceObj?.responseArr,
          };
          // await saveResearchReferences(req);

          dispatch(clearRefs());
          getProject();
          dispatch(setMendelyLoading(false));
        }
        dispatch(clearRefs());
        dispatch(setMendelyLoading(false));

        setState((prev) => ({ ...prev, loading: false }));
        getProject();
      });
  };
  return (
    <Dialog onClose={onClose} open={true} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack justifyContent="center" alignItems="center">
          <img
            src={Logo}
            alt=""
            height="50"
            width="50"
            style={{ borderRadius: "6px" }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        {localStorage.getItem("access") == null ? (
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <h1>Connect your account to start importing your files</h1>

            {!loading && (
              <Button
                onClick={initiateOAuth}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  background:
                    "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                  },
                }}
              >
                Connect
              </Button>
            )}
            {loading && <TailSpinLoader />}
          </Stack>
        ) : (
          <>
            {!currentPdfUrl && (
              <Stack alignItems="center">
                <Stack direction="row" columnGap={2}>
                  <Button
                    onClick={searchItems}
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      mt: 2,
                      background:
                        "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                      },
                    }}
                  >
                    Fetch Papers From Library
                  </Button>
                </Stack>
                {state.loading && (
                  <Oval
                    color="#cc0000"
                    wrapperClass="w-12"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#cc0000"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                )}
              </Stack>
            )}
            {/* {currentPdfUrl ? ( 
              <div>
                <Button onClick={clearPdf}>
                  <ArrowBackIcon />
                </Button>
                <iframe
                  title="pdf"
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  src={currentPdfUrl}
                />
              </div>
             ) : (  */}
            <List>
              <>
                {/* {state.searchData && state.searchData.length !== 0 && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          onChange={(e) => selectAll(e, state.searchData)}
                        />
                      </ListItemIcon>

                      <ListItemText primary={`Select All`} />
                    </ListItemButton>
                  </ListItem>
                )} */}
                {state.searchData &&
                  state.searchData.map((value, id) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItem key={value} disablePadding>
                        {`${id + 1}.`}

                        <ListItemButton
                          disabled={value.alreadyAdded}
                          onClick={handleToggle(value)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disabled={checked[0]}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#353535",
                                },
                              }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <InsertDriveFile />
                          </ListItemAvatar>
                          <ListItemText
                            // onClick={() => viewFile(value)}
                            id={labelId}
                            //split the title name ... if it is too long
                            primary={
                              value.file_name?.length > 30
                                ? `${value.file_name.substring(0, 30)}...`
                                : value.file_name
                            }
                          />
                          {value.alreadyAdded && (
                            <Chip
                              label="Uploaded"
                              color="success"
                              sx={{
                                height: "20px",
                                borderRadius: "5px !important",
                              }}
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </>
            </List>
            {/* )} */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {checked &&
          checked.length !== 0 &&
          !currentPdfUrl &&
          (state.btn ? (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#FFFFFF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"14px"} sx={{ color: "#FFFFFF" }}>
                Uploading ...
              </Typography>
            </Stack>
          ) : (
            <Button
              variant="contained"
              disabled={state.btn}
              sx={{
                textTransform: "capitalize",
                color: state.btn ? "#FFFFFF" : "#FFFFFF",
                background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                },
              }}
              onClick={saveMultipleFile}
            >
              Import
            </Button>
          ))}
      </DialogActions>
      {/* <DialogActions>
        {checked && checked.length !== 0 && !currentPdfUrl && (
          <Button
            variant="contained"
            disabled={state.btn}
            sx={{ textTransform: "capitalize" }}
            onClick={saveMultipleFile}
          >
            {state.btn ? "Uploading" : "Import"}
          </Button>
        )}
      </DialogActions> */}
    </Dialog>
  );
};
