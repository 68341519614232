import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { featuresCard } from "./FeaturesCard";
import { useSelector } from "react-redux";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import hero_bg from "../../../assets/images/NewImages/hero_bg.png";
import { Helmet } from "react-helmet";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  padding: "64px 40px 146px 40px",
  marginInline: "10px",
  marginBottom: "10vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "38px",
    padding: "64px 30px 0 30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "38px",
    padding: "64px 10px 0 10px",
  },
}));

const FeatureDetails = () => {
  const { features } = useSelector((state) => state.blogs);
  console.log("Features", features);
  const { id } = useParams();
  console.log("UseParams Id", id);
  const location = useLocation();
  const isSmall = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [item, setItem] = useState(features || null);
  console.log("Item", item);
  console.log("Features Card", featuresCard);

  useEffect(() => {
    // Retrieve the selected blog post based on the updated `id` param
    const selectedFeature = featuresCard.find((feature) => feature.id === id);

    if (selectedFeature) {
      setItem(selectedFeature);
      localStorage.setItem("selectedFeature", JSON.stringify(selectedFeature));
    } else {
      navigate("/features");
    }
  }, [id, navigate]);

  useEffect(() => {
    const storedItem = localStorage.getItem("selectedFeature");
    if (storedItem) {
      setItem(JSON.parse(storedItem));
    }
  }, []);

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });

  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  console.log("FeaturesCard", featuresCard);

  if (!item) {
    // Redirect to /blog if no blog post matches
    return <Navigate to="/features" />;
  }

  return (
    <>
      <Box>
        <Helmet>
          <script type="application/ld+json">
            {`
{
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "ResearchPal",
  "url": "https://researchpal.co/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://researchpal.co/features/${item?.id}{search_term_string}",
    "query-input": "required name=search_term_string"
  }
}
          `}
          </script>
        </Helmet>
        <Box
          sx={{
            zIndex: 1,
            p: 2,
            position: "sticky",
            right: 5,
            left: 5,
            top: 1,
            backgroundColor: "rgba(254, 254, 254, 0.32)",
            backdropFilter: "blur(12.17898178100586px)",
          }}
        >
          <LandingHeader onClick={(e) => toggle(e)} />
        </Box>

        <RootStyle id="root-style">
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Box
              sx={{
                borderRadius: { xs: "40px", sm: "64px" },
                padding: { xs: "30px", sm: "59px 64px" },
                display: "flex",
                gap: 4,
                justifyContent: { xs: "center", lg: "space-between" },
                flexWrap: { xs: "wrap", lg: "nowrap" },
                backgroundImage: `url(${hero_bg})`,
                backgroundSize: "cover",
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography
                  variant="h1"
                  sx={{
                    color: "#17CEAD",
                    fontSize: { xs: "28px", sm: "32px" },
                    fontFamily: "Righteous",
                    textAlign: { xs: "center", lg: "unset" },
                  }}
                >
                  {item?.subTitle ? item?.subTitle : item?.title}
                </Typography>

                <Typography
                  variant="p"
                  sx={{
                    color: "#181D14",
                    fontSize: "16px",
                    fontFamily: "Raleway",
                  }}
                >
                  {item?.textOne?.split(/(\*\*.*?\*\*)/).map((part, index) =>
                    part.startsWith("**") && part.endsWith("**") ? (
                      <span key={index} style={{ fontWeight: "bold" }}>
                        {part.slice(2, -2)}
                      </span>
                    ) : part.includes("\n") ? (
                      // Split by '\n' to handle line breaks
                      part?.split("\n").map((subPart, subIndex) => (
                        <React.Fragment key={subIndex}>
                          {subPart}
                          <br /> {/* Insert line break after each newline */}
                        </React.Fragment>
                      ))
                    ) : (
                      part
                    )
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  flex: { xs: 0, lg: 1 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: { xs: "100%", sm: "auto" },
                  height: { xs: "100%", sm: "auto" },
                }}
              >
                {item?.videoOne && (
                  <Box
                    component="iframe"
                    sx={{
                      width: { xs: "auto", sm: "500px", md: "560px" },
                      height: { xs: "auto", sm: "315px" },
                      borderRadius: { xs: "4px", sm: "32px" },
                      border: "none", // Optional, if you don't want any borders.
                    }}
                    src={item?.videoOne}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                borderRadius: { xs: "40px", sm: "64px" },
                padding: {
                  xs: "27px 20px",
                  sm: "27px 60px 0px 60px",
                  lg: "27px 0px 27px 64px",
                },
                display: "flex",
                gap: 4,
                justifyContent: { xs: "center", lg: "space-between" },
                flexWrap: { xs: "wrap", lg: "nowrap" },
                backgroundImage: `url(${hero_bg})`,
                backgroundSize: "cover",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  padding: { xs: "0px", sm: "30px 0px" },
                  width: "100%",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "#181D14",
                    fontSize: { xs: "24px", sm: "32px" },
                    fontFamily: "Righteous",
                    textAlign: { xs: "center", lg: "unset" },
                  }}
                >
                  {item?.headingOne}
                </Typography>

                <Typography
                  variant="p"
                  sx={{
                    color: "#181D14",
                    fontSize: "16px",
                    fontFamily: "Raleway",
                  }}
                >
                  {item?.textTwo?.split(/(\*\*.*?\*\*)/).map((part, index) => {
                    if (part.startsWith("**") && part.endsWith("**")) {
                      return (
                        <span key={index} style={{ fontWeight: "bold" }}>
                          {part.slice(2, -2)}
                        </span>
                      );
                    } else {
                      // Split text by `\n` and insert line breaks
                      return part?.split("\n").map((line, lineIndex) => (
                        <React.Fragment key={`${index}-${lineIndex}`}>
                          {line}
                          {lineIndex < part?.split("\n").length - 1 && <br />}
                        </React.Fragment>
                      ));
                    }
                  })}
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  width: "100%", // Box width
                  height: "auto",
                  borderRadius: { xs: "4px", sm: "32px" },
                }}
              >
                <img
                  src={item?.imgTwo}
                  alt={item?.imgTwoAlt}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Ensures the image fully covers the Box
                    objectPosition: { xs: "center left", lg: "top left" },
                  }}
                />
              </Box>
            </Box>

            {item?.textFour ? (
              <Box
                sx={{
                  borderRadius: { xs: "40px", sm: "64px" },
                  padding: {
                    xs: "27px 20px",
                    sm: "27px 27px 0px 64px",
                    lg: "27px 57px 27px 0px",
                  },
                  display: "flex",
                  gap: 4,
                  justifyContent: { xs: "center", lg: "space-between" },
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  backgroundImage: `url(${hero_bg})`,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    width: "100%", // Box width
                    height: "auto",
                    borderRadius: { xs: "4px", sm: "32px" },
                  }}
                >
                  <img
                    src={item?.imgFour}
                    alt={item?.imgFourAlt}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Ensures the image fully covers the Box
                      objectPosition: "top right",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: { xs: "0px", sm: "30px 0px" },
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#181D14",
                      fontSize: { xs: "24px", sm: "32px" },
                      fontFamily: "Righteous",
                      textAlign: { xs: "center", lg: "unset" },
                    }}
                  >
                    {item?.headingThree}
                  </Typography>

                  <Typography
                    variant="p"
                    sx={{
                      color: "#181D14",
                      fontSize: "16px",
                      fontFamily: "Raleway",
                    }}
                  >
                    {item?.textFour?.split(/\n/).map((paragraph, i) => (
                      <React.Fragment key={i}>
                        {paragraph?.split(/(\*\*.*?\*\*)/).map((part, index) =>
                          part.startsWith("**") && part.endsWith("**") ? (
                            <span key={index} style={{ fontWeight: "bold" }}>
                              {part.slice(2, -2)}
                            </span>
                          ) : (
                            part
                          )
                        )}
                        {i < item?.textFour?.split(/\n/).length - 1 && <br />}{" "}
                        {/* Add line breaks */}
                      </React.Fragment>
                    ))}
                  </Typography>
                </Box>
              </Box>
            ) : null}

            {item?.textFive ? (
              <Box
                sx={{
                  borderRadius: { xs: "40px", sm: "64px" },
                  padding: {
                    xs: "27px 20px",
                    sm: "27px 27px 0px 64px",
                    lg: "27px 0px 27px 57px",
                  },
                  display: "flex",
                  gap: 4,
                  justifyContent: { xs: "space-between", lg: "unset" },
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                  backgroundImage: `url(${hero_bg})`,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: { xs: "0px", sm: "30px 0px" },
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#181D14",
                      fontSize: { xs: "24px", sm: "32px" },
                      fontFamily: "Righteous",
                      textAlign: { xs: "center", lg: "unset" },
                    }}
                  >
                    {item?.headingFour}
                  </Typography>

                  <Typography
                    variant="p"
                    sx={{
                      color: "#181D14",
                      fontSize: "16px",
                      fontFamily: "Raleway",
                    }}
                  >
                    {item?.textFive?.split(/\n/).map((paragraph, i) => (
                      <React.Fragment key={i}>
                        {paragraph?.split(/(\*\*.*?\*\*)/).map((part, index) =>
                          part.startsWith("**") && part.endsWith("**") ? (
                            <span key={index} style={{ fontWeight: "bold" }}>
                              {part.slice(2, -2)}
                            </span>
                          ) : (
                            part
                          )
                        )}
                        {i < item?.textFive?.split(/\n/).length - 1 && <br />}{" "}
                        {/* Add line breaks */}
                      </React.Fragment>
                    ))}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    width: "100%", // Box width
                    height: "auto",
                    borderRadius: { xs: "4px", sm: "32px" },
                  }}
                >
                  <img
                    src={item?.imgFive}
                    alt={item?.imgFiveAlt}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Ensures the image fully covers the Box
                      objectPosition: "top left",
                    }}
                  />
                </Box>
              </Box>
            ) : null}

            <Box
              sx={{
                borderRadius: { xs: "40px", sm: "64px" },
                padding: {
                  xs: "27px 20px",
                  sm: "27px 27px 0px 64px",
                  lg: "32px 64px 0px 64px",
                },
                display: "flex",
                gap: 3,
                flexDirection: "column",
                backgroundImage: `url(${hero_bg})`,
                backgroundSize: "cover",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "#181D14",
                  fontSize: { xs: "24px", sm: "32px" },
                  fontFamily: "Righteous",
                  textAlign: "center",
                }}
              >
                {item?.headingTwo}
              </Typography>

              <Typography
                variant="p"
                sx={{
                  color: "#181D14",
                  fontSize: "16px",
                  fontFamily: "Raleway",
                  // textAlign: "center",
                }}
              >
                {item?.textThree?.split(/(\*\*.*?\*\*)/).map((part, index) => {
                  if (part.startsWith("**") && part.endsWith("**")) {
                    return (
                      <span key={index} style={{ fontWeight: "bold" }}>
                        {part.slice(2, -2)}
                      </span>
                    );
                  } else {
                    // Split non-bold text by `\n` and handle line breaks
                    return part?.split("\n").map((line, lineIndex) => (
                      <React.Fragment key={`${index}-${lineIndex}`}>
                        {line}
                        {lineIndex < part?.split("\n").length - 1 && <br />}
                      </React.Fragment>
                    ));
                  }
                })}
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  width: "100%", // Box width
                  maxHeight: "400px",
                  borderTopLeftRadius: { xs: "4px", sm: "32px" },
                  borderTopRightRadius: { xs: "4px", sm: "32px" },
                  borderBottomLeftRadius: { xs: "4px", sm: "0px" },
                  borderBottomRightRadius: { xs: "4px", sm: "0px" },
                }}
              >
                <img
                  src={item?.imgThree}
                  alt={item?.imgThreeAlt}
                  style={{
                    width: "200%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center left",
                  }}
                />
              </Box>
            </Box>

            {item?.relatedReading && (
              <Box sx={{ mt: 3, ml: { xs: 3, sm: 7 } }}>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: 700,
                    fontSize: { xs: "20px", sm: "24px" },
                    fontFamily: "Raleway",
                  }}
                >
                  {item?.relatedReading.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    whiteSpace: "pre-line",
                    fontSize: { xs: "16px", sm: "18px" },
                    fontFamily: "Raleway",
                    mt: 2,
                  }}
                >
                  {item?.relatedReading.description
                    ?.split("\n") // Split by line breaks
                    .map((line, lineIndex) => {
                      let modifiedLine = line;

                      featuresCard.forEach((feature) => {
                        // Case-insensitive match for the blog category in the line text
                        if (
                          line
                            .toLowerCase()
                            .includes(feature.title.toLowerCase())
                        ) {
                          modifiedLine = line.replace(
                            new RegExp(feature.title, "i"), // Case-insensitive replacement
                            `<a href="/features/${feature.id}" style="color: #4A90E2;" target="_blank">${feature.title}</a>`
                          );
                        }
                      });

                      // Wrap each line in a <div> to ensure it appears on a new line without extra bullet points
                      return (
                        <div
                          key={lineIndex}
                          dangerouslySetInnerHTML={{
                            __html: modifiedLine,
                          }}
                        />
                      );
                    })}
                </Typography>
              </Box>
            )}
          </Box>
        </RootStyle>

        <Footer />

        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </Box>
    </>
  );
};

export default FeatureDetails;
