import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 500,
  // fontSize: "13px",
  color: "#353535",
  padding: "10px",
});

const EssayGuidelineDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          //   flexWrap: "wrap-reverse",
        }}
      >
        <Text sx={{ fontWeight: 600 }}>
          Guidelines for Using the "Essay Writing" Feature
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: "absolute",
            right: 8,
            top: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Text>
          <strong>1. Essay Title:</strong> Enter the title of your essay in the
          title text box.
        </Text>
        <Text>
          <strong>2. Essay Description:</strong> Provide a brief description of
          your essay in the description box. Avoid including requests for
          citations, references, or irrelevant instructions. For essays
          requiring proper references, use the "Literature Review" feature
          instead.
        </Text>
        <Text>
          <strong>3. Word Count:</strong> Specify the word count range to ensure
          the essay meets your length requirements.
        </Text>
        <Text>
          <strong>4. Essay Type:</strong> Use the dropdown menu to select the
          type of essay that best fits your needs (e.g., basic, descriptive,
          narrative, persuasive, expository).
        </Text>
        <Text>
          <strong>5. Include Headings: </strong> Toggle this option to decide
          whether the essay should include headings. Note: In some cases, the
          system (LLM) might add headings even if this option is turned off.
        </Text>
        <Text>
          <strong>Important Note:</strong> Ensure your instructions are clear
          and relevant to the essay. Overloading the description with extra
          details may lead to suboptimal results.
        </Text>
      </DialogContent>
    </Dialog>
  );
};

export default EssayGuidelineDialog;
