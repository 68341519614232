import ProjectLibrary from "../../../assets/images/NewImages/project-library-illustration.png";
import LiteratureReview from "../../../assets/images/NewImages/literature-review-illustration.png";
import TextEditor from "../../../assets/images/NewImages/text-editor-illustration.png";
import SearchImage from "../../../assets/images/NewImages/search-illustration.png";
import AskResearch from "../../../assets/images/NewImages/ask-researchpal-illustration.png";
import TextCitation from "../../../assets/images/NewImages/text-citations-illustration.png";
import LitImg1 from "../../../assets/images/LitImg1.png";
import LitImg2 from "../../../assets/images/LitImg2.png";
import InTextImg1 from "../../../assets/images/inTextImg1.png";
import InTextImg2 from "../../../assets/images/inTextImg2.png";
import InTextImg3 from "../../../assets/images/inTextImg3.png";
import InsightsImg1 from "../../../assets/images/insightsImg1.png";
import InsightsImg2 from "../../../assets/images/insightsImg2.png";
import InsightsImg3 from "../../../assets/images/insightsImg3.png";
import RGImg1 from "../../../assets/images/RGImg1.png";
import RGImg2 from "../../../assets/images/RGImg2.png";
import RGImg3 from "../../../assets/images/RGImg3.png";
import SearchImg1 from "../../../assets/images/SearchImg1.png";
import SearchImg2 from "../../../assets/images/SearchImg2.png";
import SearchImg3 from "../../../assets/images/SearchImg3.png";
import AITootlImg1 from "../../../assets/images/AIToolImg1.png";
import AITootlImg2 from "../../../assets/images/AIToolImg2.png";
import AITootlImg3 from "../../../assets/images/AIToolImg3.png";
import AITootlImg4 from "../../../assets/images/AIToolImg4.png";
import EssayType from "../../../assets/images/Essay Type.png";
import EssayIllustration from "../../../assets/images/essayIllusration.png";
import WordCounter from "../../../assets/images/wordCounter.png";
import EssayGenerated from "../../../assets/images/GeneratedEssay.png";

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
};

export const featuresCard = [
  {
    id: createSlug("Literature Review"),
    title: "Literature Review",
    subTitle: "Literature Review Generator",
    img: LiteratureReview,
    alt: "Generating the literature review using new ideas.",
    description:
      "Generate a Literature Review for your research question in seconds with authentic citations from credible research sources.",
    textOne:
      "**ResearchPal** is a cutting-edge platform designed to revolutionize the research process by combining AI innovation with academic rigor. From simplifying literature reviews to optimizing workflows, ResearchPal empowers researchers to focus on what truly matters—advancing knowledge. The **Literature Review** writing service, designed to simplify and accelerate the process of crafting well-structured literature reviews. Our cutting-edge platform ensures high-quality results, saving researchers valuable time while enhancing productivity.",
    videoOne: "https://www.youtube.com/embed/wffi_OaPy4c?si=YFqGLTE_uM-ZdZOd",
    headingOne: "Leveraging Advanced AI",
    textTwo:
      "ResearchPal navigates a vast network of academic databases to extract **authentic and credible material** for your dissertation literature review. With AI-driven precision, it sifts through millions of research papers, ensuring only relevant and reliable content is used to support your study. ResearchPal seamlessly integrates with leading academic banks such as **Nature, arXiv, Springer, and Semantic Scholar,** as well as domain-specific databases tailored to your research field. This ensures comprehensive coverage of relevant literature and access to the most authoritative sources available.",
    imgTwo: LitImg1,
    imgTwoAlt:
      "Literature Review has been generated for the topic of Impacts of AI and ML in Healthcare and Education by choosing the source as Web and in the short format",
    headingTwo: "Seamless Referencing and Reliable AI Precision",
    textThree:
      "Our platform provides **in-text citations and auto-referencing,** supporting all major referencing formats, including APA, MLA, and Chicago. You can easily switch formats with just a click. Additionally, ResearchPal’s unique **library feature** automatically saves abstracts of cited papers, enabling quick access to essential insights during your review process. Unlike other AI tools prone to hallucinations, ResearchPal guarantees factually **accurate outputs,** ensuring your literature review is free from errors and misrepresented data. Our AI operates with transparency and academic rigor.",
    imgThree: LitImg2,
    imgThreeAlt: "Five References are in the Reference Section in APA format ",
    relatedReading: {
      title: "Related Reading",
      description:
        "• In-Text Citations \n\n• Reference Generator\n\n• AI-Powered Tools \n\n • Search Papers  \n\n • Paper Insights",
    },
  },

  {
    id: createSlug("AI Essay Writer"),
    title: "AI Essay Writer",
    subTitle: "AI Essay Writer",
    img: EssayIllustration,
    alt: `A top-down view of a workspace featuring a laptop, open notebook with hands writing, coffee cup, smartphone, and various papers and stationery on a textured blue background, representing essay writing`,
    description:
      "Generate customized essays of any type or length, complete with headings and authentic citations from credible sources.",
    textOne:
      "ResearchPal continues to revolutionize academic processes with the launch of its advanced Essay Writing feature, combining AI precision with academic excellence to deliver high-quality essays tailored to your specific needs. Whether you're crafting a compelling persuasive essay, narrating a story, or delving into descriptive analysis, ResearchPal ensures a seamless experience for all types of essays.",
    videoOne: "https://www.youtube.com/embed/S56zZTcvGzw?si=LL8zbUQ4OJ1pBEo3",
    headingOne: "",
    textTwo:
      "The main features include: \n\n● **Descriptive Essays: ** Paint vivid pictures through words with essays that evoke emotions and create immersive experiences.\n● **Expository Essays:** Present well-researched, factual information with clarity and precision.\n● **Narrative Essays:** Craft engaging stories with well-structured plots and strong emotional connections.\n● **Persuasive Essays:** Develop strong arguments and convince your audience with impactful reasoning.\n●	**Basic Essays:** Simplify the writing process for shorter, straightforward pieces.",
    imgTwo: EssayType,
    imgTwoAlt:
      "Screenshot of the ResearchPal essay writing feature showing a clean interface. Users can input an essay topic, provide an optional description, and select essay type (e.g., Descriptive, Narrative, Persuasive) with word count customization.",
    headingTwo: "",
    textThree:
      "Unlike other AI tools, ResearchPal prioritizes accuracy, authenticity, and customization. Our AI ensures that your essays are free from errors, misrepresentations, plagiarism or unverified content, delivering high-quality results every time.\n\nWith ResearchPal’s cutting-edge Essay Writing feature, save time, enhance productivity, and focus on what truly matters—producing impactful, well-crafted essays that leave a lasting impression.",
    imgThree: AITootlImg2,
    imgThreeAlt:
      "Rephrasing Argument is being generated by the Text Tuner of AI Tools in the Document Section",
    headingThree: "Tailored Essay Lengths",
    textFour: `Choose from a wide range of word counts, from **100 to 2,500 words,** to suit the specific requirements of your assignment. Whether you need a brief summary or an in-depth exploration, ResearchPal delivers content that aligns with your academic goals.`,
    imgFour: WordCounter,
    imgFourAlt:
      "Screenshot of the ResearchPal essay writing feature where users can input an essay topic, add an optional detailed description, select an essay type, set the word count (e.g., 500-800 words), and toggle the option to include headings for a structured output.",
    headingFour: "Dynamic Customization with AI Editing Tools",
    textFive: `Once your essay is generated, ResearchPal’s intuitive **Document Editor** empowers you to fine-tune your work with **advanced AI tools** designed to enhance every aspect of your writing.\n\n    **●	Headings and Structure: ** Add or adjust headings to create a clear, organized framework for your essay.\n **●	AI-Driven Enhancements: ** Effortlessly refine your content using tools such as rephrasing, summarization, and tone adjustment to align your essay with your intended audience and purpose.`,
    imgFive: EssayGenerated,
    imgFiveAlt: `Screenshot of a generated essay on ResearchPal titled "The Evolution of AI Avatar Generators: Transforming Digital Communication". The essay includes a structured layout with a title, introduction, and section headings such as "Technological Advancements in AI Avatar Generators", "Transforming Communication and Education", and "Revolutionizing Marketing Strategies". Users can copy or edit the content with options available at the top.`,
    relatedReading: {
      title: "Related Reading",
      description:
        "• In-Text Citations \n\n• Literature Review\n\n• AI-Powered Tools\n\n• Reference Generator \n\n • Search Papers  \n\n • Paper Insights",
    },
  },

  {
    id: createSlug("Reference Generator"),
    title: "Reference Generator",
    img: AskResearch,
    alt: "Robot reference generator",
    description:
      "Generate references in any format by adding the title of your research paper or uploading a PDF.",
    textOne:
      "**ResearchPal** is a contemporary platform that combines academic excellence and AI innovation to transform the research process. By streamlining academic writing and streamlining workflows, ResearchPal frees up researchers to concentrate on what really counts—the advancement of knowledge. While performing research, one must note that only credible and properly referenced material holds value in the scientific community. Plagiarism and copying a peer or professor’s work without permission and giving credit is frowned upon.",
    videoOne: "https://www.youtube.com/embed/-rdB15bPMZc?si=SOT8nsq8IzgskgHN",
    headingOne: "Citation Creation and Management",
    textTwo:
      "The **Reference Generator** feature of ResearchPal simplifies the process of creating accurate and properly formatted references for your research. With support for popular citation styles such as **APA, MLA, Harvard, and Chicago,** this tool ensures your references meet academic standards effortlessly.\nWhen you generate references using ResearchPal, the platform not only formats them precisely but also automatically saves the corresponding **abstracts** in the **Library**. This allows you to access essential details about each source whenever needed, streamlining your research work and providing valuable context during the research process. In case you did not get your paper from our **Search Papers ** feature, you can still add its reference in the reference section.",
    imgTwo: RGImg1,
    imgTwoAlt: "Five References are in the Reference Section in APA format ",
    headingTwo: "Streamlined Citation Management for Impactful Research",
    textThree:
      "By combining automation, customization, and enhanced accessibility, the Reference Generator empowers researchers to manage citations efficiently, so they can focus on delivering impactful research. With seamless integration into your workflow, it reduces the time spent on formatting and organizing references. This ensures researchers can dedicate more energy to analysis, writing, and innovation.",
    imgThree: RGImg3,
    imgThreeAlt:
      "All References are being added to the Document Section along with a Cite button to enable in-text citation.",
    headingThree: "",
    textFour: `The **Reference Generator** offers a manual option to add references, giving users complete control to include custom sources not already in the database. Additionally, the citations are already present in your generated text and ResearchPal will seamlessly integrate them into the reference list, ensuring consistency and accuracy.\nWith ResearchPal’s intuitive interface, switching between citation formats is as simple as a click, making it easy to adapt to different submission requirements or publication guidelines. This feature not only saves time but also eliminates the common errors associated with manual referencing, ensuring your work is polished and professional.`,
    imgFour: RGImg2,
    imgFourAlt:
      "A manual reference for the Consequences of user engagement in gamification, information & management is being added in the Reference Section",
    relatedReading: {
      title: "Related Reading",
      description:
        "• In-Text Citations \n\n• Literature Review\n\n• AI-Powered Tools \n\n • Search Papers  \n\n • Paper Insights",
    },
  },
  {
    id: createSlug("AI-Powered Tools"),
    title: "AI-Powered Tools",
    subTitle: "AI Tools for Literature Review",
    img: TextEditor,
    alt: "AI Tools rewriting the text",
    description:
      "Rewrite your paper in any tone and ask ResearchPal to increase or decrease the length of your text in different languages.",
    textOne:
      "After your literature review has been created and pasted into the editor, ResearchPal provides an advanced set of AI tools to help you polish and improve it. These tools are made so that the editing process is more efficient and guarantees that the editing is done to your exact specifications.\n\nResearchPal ensures your literature review is not only well-written but also professionally refined. Whether you're looking to simplify complex sentences, improve readability, or tailor the tone to a specific audience, these tools empower you to achieve perfection with ease.",
    videoOne: "https://www.youtube.com/embed/ku8_oWHxZY0?si=DIQTfCdAWre_FzsP",
    headingOne: "",
    textTwo:
      "The main features include: \n\n● **Ask ResearchPal:** Get prompt responses to your enquiries on the content. This tool offers accurate, AI-driven help whether you require clarification, recommendations for improvements, or further references.\n● **Cite:** Easily include in-text citations to back up your arguments. The reference generator and the tool work together precisely, enabling you to follow popular citation styles like APA, MLA, Harvard, and Chicago while citing reliable sources with only one click.\n● **Translate:** Translate your text into multiple languages, including Chinese, Dutch, Vietnamese, and more, making your research accessible to a global audience.",
    imgTwo: AITootlImg1,
    imgTwoAlt:
      "Text has been selected in the Document Section to implement the AI Tools on it, which include Ask ResearchPal, Cite, Tones, Translate and Text Tuner",
    headingTwo: "Empower Your Research with Advanced AI Editing Tools",
    textThree:
      "These AI tools empower researchers to edit, enhance, and perfect their work directly within ResearchPal, combining efficiency and precision to deliver high-quality results tailored to any academic or professional need. From refining language to aligning with specific formatting guidelines, the platform streamlines the editing process, ensuring your work is ready for publication or presentation with minimal effort. Whether you're an experienced academic or a budding researcher, these tools adapt to meet your unique needs.",
    imgThree: AITootlImg2,
    imgFourAlt:
      "Academic Tone has been chosen from the Tones to change the selected text in the Document Section.",
    headingThree: "",
    textFour: `● **Tones:** Adjust the tone of your writing to match the purpose and audience of your literature review. Options include:\n\n    **○ Academic:** For formal and scholarly writing.\n  **○ Casual:** For conversational pieces.\n **○ Persuasive:** To convince readers with strong arguments.\n **○ Boldly:** For assertive and confident language.\n **○ Friendly:** To maintain a warm and approachable tone.\n **○ Professionally:** For clear, authoritative, and polished communication.\n\nSelecting the appropriate tone for your literature review is crucial to effectively communicating your message and engaging your audience. Whether academic, casual, persuasive, bold, friendly, or professional, aligning the tone with your purpose ensures clarity and impact.`,
    imgFour: AITootlImg3,
    imgFiveAlt:
      "Opposing Argument is being generated by the Text Tuner of AI Tools in the Document Section",
    headingFour: "",
    textFive: `● **Text Tuner:** Modify your content with powerful AI options:\n\n    **○ Rephrasing:** Restate sentences while maintaining meaning.\n  **○ Continue Writing:** Expand on existing ideas seamlessly.\n **○ Write In-Depth:** Add detailed analysis or elaboration.\n **○ Summarize:**  Condense lengthy sections into concise summaries.\n **○ Opposing Argument:** Generate counterpoints for balanced discussions.\n **○ Make Longer:** Expand the text without verbosity.
        Make Shorter: Condense text while preserving key ideas.\n**○ Generate Outline:** Create a structured framework for your literature review.`,
    imgFive: AITootlImg4,
    imgThreeAlt:
      "Rephrasing Argument is being generated by the Text Tuner of AI Tools in the Document Section",
    relatedReading: {
      title: "Related Reading",
      description:
        "• In-Text Citations \n\n• Literature Review\n\n• Reference Generator \n\n • Search Papers  \n\n • Paper Insights",
    },
  },
  {
    id: createSlug("In-Text Citations"),
    title: "In-Text Citations",
    img: TextCitation,
    alt: "Hand holding the letter “T”",
    description:
      "Use ResearchPal to find authentic citations from credible journals for your claims within seconds.\n\n\n",
    textOne:
      "**ResearchPal** is a revolutionary platform designed to automate the research process by combining AI innovation with academic precision. By streamlining academic writing and streamlining workflows, ResearchPal enables academics to concentrate on what really counts—the advancement of knowledge. While performing research, one must note that only credible and properly referenced material is given value in the scientific community. Plagiarism and copying a peer or professor’s work without permission and giving credit is frowned upon.",
    videoOne: "https://www.youtube.com/embed/J8SwLd4ofE0?si=agZjo3_Bu-CgSHQ6",
    headingOne: "Trustworthy Academic Resources",
    textTwo:
      "ResearchPal’s In-Text Citation feature revolutionizes how researchers find and integrate credible sources into their work. Powered by advanced AI, this tool simplifies the process of locating accurate references from trusted academic databases, including **Nature, arXiv, Springer, and Semantic Scholar.** Whether you’re building a case for your argument or supporting a key claim, ResearchPal delivers relevant citations in seconds, ensuring your research is rooted in reliable evidence. Researchers often opt for leaving citations for the very end and do it like manual labor, however, ResearchPal automates the whole process side by side as one moves forward in writing the thesis.",
    imgTwo: InTextImg1,
    imgTwoAlt:
      "Text has been selected in the Document Section to implement the AI Tool Cite on it",
    headingTwo: "Enhance Research Credibility",
    textThree:
      "By combining speed, accuracy, and ease of use, the In-Text Citation feature enhances the credibility of your research while streamlining your workflow. It’s an essential tool for students, academics, and professionals who value precision and efficiency. With ResearchPal, citing credible sources has never been easier, allowing you to focus on what truly matters—producing high-quality research that advances knowledge.",
    imgThree: InTextImg2,
    imgFourAlt: "Five References are in the Reference Section in APA format",
    headingThree: "",
    textFour: `The platform supports all major **citation formats**, such as **APA, MLA, Harvard and Chicago**, allowing you to easily adapt to the specific requirements of your field or publication. With just a single click on the **"Cite"** button in the document section, you can seamlessly insert citations into your text. This eliminates the hassle of manual formatting and reduces the risk of errors, freeing up your time to focus on advancing your stud\n\nUnlike other tools that risk inaccuracies or incomplete data, ResearchPal ensures every citation is authentic and drawn from authoritative sources. The AI-driven system carefully evaluates millions of research papers to provide only the most relevant and reliable materials for your work. All the citations are collected in the reference section automatically. Additionally, the feature integrates smoothly with **ResearchPal’s Library**, automatically **saving abstracts** of the cited papers for quick reference.`,
    imgFour: InTextImg3,
    imgThreeAlt:
      "A pop up to choose between two research papers after clicking Cite from the AI Tools",
    relatedReading: {
      title: "Related Reading",
      description:
        "• AI-Powered Tools \n\n• Literature Review\n\n• Reference Generator \n\n • Search Papers  \n\n • Paper Insights",
    },
  },
  {
    id: createSlug("Search Papers "),
    title: "Search Papers ",
    subTitle: "Search Papers ",
    img: SearchImage,
    alt: "Searching papers with a magnifying glass",
    description:
      "Optimize your literature search by instantly saving relevant papers references and their abstracts. Find relevant papers with our domain-specific Search.",
    textOne:
      "**ResearchPal.co** is a cutting-edge platform designed to streamline and enhance the research process for scholars, students, and professionals across all disciplines. With its user-friendly interface and innovative features, ResearchPal empowers users to access, organize, and utilize research materials with ease. Whether you're managing complex projects, collaborating with peers, or simply seeking reliable resources, ResearchPal adapts to your needs.",
    videoOne: "https://www.youtube.com/embed/KIxwlgiqZFA?si=j7svQ8RQFhvFpBsG",
    headingOne: "Domain-Specific Data Banks",
    textTwo:
      "For researchers delving into specialized fields, ResearchPal offers a separate data source of domain-specific papers, meticulously curated to meet the needs of focused study and innovation. Whether you are exploring cutting-edge technologies, medical advancements, or social sciences, ResearchPal ensures you have tailored resources at your fingertips.",
    imgTwo: SearchImg1,
    imgTwoAlt:
      "Research papers related to the Limitations of Large Language models are being searched in the Semantic Search Column of the Search Papers Section",
    headingTwo: "Redefining Research Accessibility and Efficiency",
    textThree:
      "The Search Papers  feature of ResearchPal offers distinct advantages that redefine the research experience. It provides seamless access to a vast collection of research papers without the hassle of subscription barriers, saving valuable time and effort. By consolidating all your research needs into a single, intuitive platform, it streamlines the process and enhances productivity. With curated content tailored specifically to your domain of interest, it ensures that every search delivers relevant and impactful results. Start your research journey with ResearchPal—where innovation meets accessibility, and research meets simplicity.",
    imgThree: SearchImg2,
    imgFourAlt:
      "Research papers related to the Recent Advancements in AI and ML in Healthcare are being searched in the ResearchPal Search Column of the Search Papers Section",
    headingThree: "Harness the Power of Search Papers ",
    textFour: `The Search Papers  feature is at the heart of ResearchPal’s mission to simplify academic exploration. This tool brings together an extensive collection of open-source research papers from globally recognized repositories like Nature, arXiv, Springer, and Semantic Scholar. With all these resources assembled in one place, you no longer need to juggle between multiple journal logins or waste time requesting access from different platforms.`,
    imgFour: SearchImg3,
    imgThreeAlt:
      "There are options to save, source and view pdf of any searched paper along with its Summary and Abstract",
    relatedReading: {
      title: "Related Reading",
      description:
        "• AI-Powered Tools \n\n• Literature Review\n\n• Reference Generator \n\n • In-Text Citations \n\n • Paper Insights",
    },
  },
  {
    id: createSlug("Paper Insights"),
    title: "Paper Insights",
    img: ProjectLibrary,
    alt: "Magnifying glass searching inside papers",
    description:
      "Analyse research papers at superhuman speed. Automate summarizing papers, extracting methodology and contributions or synthesizing your findings.",
    textOne:
      "**ResearchPal** is a cutting-edge platform designed to revolutionize the research process by integrating AI with academic research. By optimizing academic writing and improving workflows, ResearchPal allows researchers to focus on what truly matters: advancing knowledge. In order to get a comparative analysis of various different papers understudy, you can simply get a **columnar comparison** within seconds by using the Paper Insights feature.",
    videoOne: "https://www.youtube.com/embed/xbvS20V404Y?si=oOpZthtYX2GzIEgm",
    headingOne: "Extract Key Insights From Papers",
    textTwo:
      "The Paper Insights feature of ResearchPal is designed to extract and organize critical information from research papers, enabling users to make complex studies effortlessly. One may use any paper imported from Mendeley, Zotero Search Papers  or even manual uploads. This powerful tool categorizes content into customizable columns, helping researchers focus on the details most relevant to their work.\n\nWith **Paper Insights**, you can select from a range of pre-designed columns, including:\n●	**Insights:** Highlights and major takeaways from the study.\n●	**TLDR** (Too Long; Didn't Read): Key points and summaries for quick understanding.\n●	**Conclusions:** Summarized findings and implications of the study.",
    imgTwo: InsightsImg2,
    imgTwoAlt:
      "A list of options including TLDR, Conclusions, Summarized Abstracts, Results, Summarized Introduction, Methods Used, Literature Survey, Limitations, Contributions, Datasets and Practical Implications is present in which the Summarized Abstract, Methods Used, Limitations, and Datasets are ticked and presented in the Paper Insights columns for two research papers",
    headingTwo: "Customizable Tool for Streamlined Research Analysis",
    textThree:
      "Users can fully customize their experience by selecting the columns they want to include and choosing the desired level of detail for each. The feature supports adjustable text lengths, from concise summaries to more detailed explanations, ensuring flexibility to match your specific needs.\n\nBy presenting organized, actionable insights at a glance, Paper Insights saves time, boosts productivity, and provides clarity, whether you're conducting a literature review or analyzing multiple papers. It's an invaluable tool for making informed decisions and advancing your research efficiently.",
    imgThree: InsightsImg1,
    imgThreeAlt:
      "The insights and methods used are present in columns for the chosen paper ",
    headingThree: "",
    textFour: `● **Summarized Abstract:** A concise version of the paper’s abstract.\n● **Results:** A summary of the key outcomes and findings.\n● **Summarized Introduction:** An abridged version of the paper's introduction. \n● **Methods Used:** An overview of the research methodology employed.\n● **Literature Survey:** Insights drawn from previous studies. \n● **Limitations:** Constraints and areas for future research. \n● **Contributions:** The unique value and advancements provided by the study. \n● **Datasets:** Information on the datasets used or referenced. \n● **Practical Implications:** Real-world applications or potential impacts of the research.`,
    imgFour: InsightsImg3,
    imgFourAlt:
      "A list of options including TLDR, Conclusions, Summarized Abstracts, Results, Summarized Introduction, Methods Used, Literature Survey, Limitations, Contributions, Datasets and Practical Implications is present in which the Summarized Abstract, Methods Used, Limitations, and Datasets are ticked and presented in the Paper Insights columns for two research papers",
    relatedReading: {
      title: "Related Reading",
      description:
        "• AI-Powered Tools \n\n• Literature Review\n\n• Reference Generator \n\n • In-Text Citations \n\n • Search Papers ",
    },
  },
];
