import Toast from "../../components/Toast";
import AWS from "aws-sdk";
const awsConfig = {
  // accessKeyId: "AKIAWC2A6LYAWKHRIGXW",
  // secretAccessKey: "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",
  // accessKeyId: "AKIAWC2A6LYAVDRGNS6U",
  // secretAccessKey: "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",
  // accessKeyId: "AKIAWC2A6LYA52SVE27C",
  // secretAccessKey: "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",
  accessKeyId: "AKIAWC2A6LYAR7C4GAES",
  secretAccessKey: "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",
  region: "us-west-2",
};

AWS.config.update(awsConfig);
const getObjectFromS3 = async (item) => {
  const s3 = new AWS.S3();
  const config = {
    Bucket: "veracious-documents",
    Key: item.pdfFile,
  };

  try {
    const data = await s3.getObject(config).promise();

    if (data) {
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
export const openImageFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      const contentType = data.ContentType;

      let imageType;
      if (contentType.startsWith("image/jpeg")) {
        imageType = "image/jpeg";
      } else if (contentType.startsWith("image/png")) {
        imageType = "image/png";
      } else if (contentType.startsWith("image/gif")) {
        imageType = "image/gif";
      } else {
        imageType = "image/jpeg"; // Default type
      }

      const imageBlob = new Blob([data.Body], { type: imageType });
      const imageURL = URL.createObjectURL(imageBlob);

      return imageURL; // Return the image URL
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null to indicate an error
    }
  } catch (error) {
    console.log("Error opening the file:", error);
    return null; // Return null in case of other errors
  }
};
export const openHtmlFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      const contentType = data.ContentType;
      if (contentType.startsWith("text/html")) {
        const htmlBlob = new Blob([data.Body], { type: "text/html" });
        const htmlURL = URL.createObjectURL(htmlBlob);
        return htmlURL; // Return the valid HTML URL
      } else {
        console.warn("The file is not a valid HTML document.");
        return null; // Indicate non-HTML content
      }
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null for errors
    }
  } catch (error) {
    console.error("Error opening the file:", error);
    return null; // Return null for other errors
  }
};
export const openFileFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      const pdfBlob = new Blob([data.Body], { type: "application/pdf" });
      const pdfURL = URL.createObjectURL(pdfBlob);
      return pdfURL;
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null for errors
    }
  } catch (error) {
    console.error("Error opening the file:", error);
    return null; // Return null for other errors
  }
};
export const openDocFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      const docBlob = new Blob(["\ufeff", data.Body], {
        type: "application/doc",
      });
      const docURL = URL.createObjectURL(docBlob);
      return docBlob;
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null for errors
    }
  } catch (error) {
    console.error("Error opening the file:", error);
    return null; // Return null for other errors
  }
};
export const openXlsxFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      const xlsBlob = new Blob([data.Body], {
        type: "application/vnd.ms-excel",
      });
      const xlsURL = URL.createObjectURL(xlsBlob);

      return xlsURL;
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null for errors
    }
  } catch (error) {
    console.error("Error opening the file:", error);
    return null; // Return null for other errors
  }
};

//convert jSON to pdf file
export const openMetaDataFromS3 = async (item) => {
  try {
    const data = await getObjectFromS3(item);

    if (data) {
      console.log(data);
      const jsonBlob = new Blob([data.Body], { type: "application/json" });
      //get data from json file

      return jsonBlob;
    } else {
      Toast("error", "Error opening the file");
      return null; // Return null for errors
    }
  } catch (error) {
    console.error("Error opening the file:", error);
    return null; // Return null for other errors
  }
};
