import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  styled,
  Stack,
  TextareaAutosize,
  useMediaQuery,
  Switch,
  CircularProgress, // Import CircularProgress for loader
} from "@mui/material";
import hero_bg from "../../../assets/images/NewImages/hero_bg.png";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useDispatch, useSelector } from "react-redux";
import GetStarted from "./GetStarted";
import { baseDomain } from "../../../repo/Repository";
import axios from "axios";
import Toast from "../../../components/Toast";
import EssayDisplay from "./EssayDisplay";
import NewEssay from "./NewEssay";
import {
  setEssay,
  clearEssay,
  setTopic,
  clearTopic,
  setEssayContent,
  setEssayTopic,
  setIsLoading,
  setDescription,
  clearDescription,
  setIncludeHeading,
  setWordCount,
  setEssayType,
  setProgress,
  resetProgress,
} from "./essaySlice";
import { sendErrorMail } from "../../../apiservice";
import { setPasteContent } from "../../literature review/reviewSlice";
import { useNavigate } from "react-router-dom";

const RootStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const Essay = () => {
  // const [essayType, setEssayType] = useState("");
  // const [wordCount, setWordCount] = useState("");
  const dispatch = useDispatch();
  // const [includeHeading, setIncludeHeading] = useState(false);
  // const [topic, setTopic] = useState("");
  // const { topic } = useSelector((state) => state.essay);
  const isSmall = useMediaQuery("(max-width:600px)");
  const { user } = useSelector((state) => state.user);
  // const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  console.log("Errors", errors);
  // const [description, setDescription] = useState("");
  const essayType = useSelector((state) => state.essay.essayType);
  const wordCount = useSelector((state) => state.essay.wordCount);
  const includeHeading = useSelector((state) => state.essay.includeHeading);
  const isLoading = useSelector((state) => state.essay.isLoading);
  const description = useSelector((state) => state.essay.description);
  const navigate = useNavigate();
  // const [progress, setProgress] = useState(0);
  const progress = useSelector((state) => state.essay.progress);
  console.log("Progress", progress);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // const [essay, setEssay] = useState("");
  // const { essay } = useSelector((state) => state.essay);

  const projectTitle = localStorage.getItem("projectTitle");
  const topic = useSelector(
    (state) =>
      state.essay.projects.find((project) => project.title === projectTitle)
        ?.topic
  );

  console.log("Topic", topic);

  const essay = useSelector(
    (state) =>
      state.essay.projects.find((project) => project.title === projectTitle)
        ?.essay
  );
  console.log("Essay", essay);

  const [cleanedEssay, setCleanedEssay] = useState("");

  useEffect(() => {
    // Remove markdown bold syntax (**) from the essay content
    if (essay) {
      const cleaned = essay.replace(/\*\*(.*?)\*\*/g, "$1"); // This removes the "**" around bold text
      setCleanedEssay(cleaned);
    }
  }, [essay]);

  console.log("Cleaned Essay", cleanedEssay);

  const maxCharacters = 100;
  const maxCharactersDesc = 500;

  // const handlePasteEssay = async () => {
  //   dispatch(setPasteContent(essay));
  //   let url = projectTitle.toString().toLowerCase().replace(/\s+/g, "-");
  //   navigate(`/projects/${url}/document`);
  // };

  const getIntervalTime = () => {
    if (wordCount === "500-800") return 1500;
    if (wordCount === "800-1000") return 1800;
    if (wordCount === "1000-1200") return 2000;
    if (wordCount === "1200-1500") return 2100;
    if (wordCount === "1500-2000") return 2300;
    if (wordCount === "2000-2500") return 2700;
    return 1000; // Default interval time if no match
  };

  // useEffect(() => {
  //   let progressInterval;
  //   if (isLoading) {
  //     const intervalTime = getIntervalTime(); // Get the interval time based on wordCount
  //     progressInterval = setInterval(() => {
  //       // setProgress((prev) => {
  //       dispatch(
  //         setProgress((prev) => {
  //           if (prev >= 100) {
  //             setIsLoading(false); // Stop loading when progress reaches 100%
  //             dispatch(resetProgress()); // Reset progress to 0%
  //             return 0;
  //           }
  //           return prev + 5; // Increment progress
  //         })
  //       );
  //     }, intervalTime); // Use the calculated interval time
  //   }
  //   return () => {
  //     clearInterval(progressInterval); // Cleanup on component unmount
  //   };
  // }, [isLoading, wordCount, dispatch]); // Include wordCount as a dependency

  useEffect(() => {
    let progressInterval;
    if (isLoading) {
      const intervalTime = getIntervalTime(); // Get the interval time based on wordCount
      progressInterval = setInterval(() => {
        dispatch((dispatch, getState) => {
          const currentProgress = getState().essay.progress; // Get the current progress from Redux state
          if (currentProgress >= 100) {
            dispatch(resetProgress()); // Reset progress to 0%
          } else {
            dispatch(setProgress(currentProgress + 5)); // Increment progress
          }
        });
      }, intervalTime); // Use the calculated interval time
    }
    return () => {
      clearInterval(progressInterval); // Cleanup on component unmount
    };
  }, [isLoading, wordCount, dispatch]);

  const handlePasteEssay = async () => {
    // Combine topic, two-line spaces, and essay
    const contentWithTopic = `${topic}\n\n${cleanedEssay}`;

    // Dispatch the combined content
    dispatch(setPasteContent(contentWithTopic));

    // Navigate to the desired URL
    let url = projectTitle.toString().toLowerCase().replace(/\s+/g, "-");
    navigate(`/projects/${url}/document`);
  };

  // const handlePasteEssay = async () => {
  //   const contentWithTopic = `${topic}\n\n${essay}`;

  //   // Split the content into blocks by paragraphs or lines
  //   const paragraphs = contentWithTopic.split("\n\n");

  //   // Process each paragraph
  //   const formattedContent = paragraphs.map((paragraph) => {
  //     // Replace **bold** with the correct Tiptap format
  //     return paragraph.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
  //       return `**${p1}**`; // just replace with the bold syntax for now
  //     });
  //   }).join("\n\n");

  //   // Now create a Tiptap-compatible structure for the editor
  //   const editorContent = formattedContent.split("\n\n").map((text) => ({
  //     type: "paragraph",
  //     content: [
  //       {
  //         type: "text",
  //         text: text.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
  //           return p1; // just text for now
  //         }),
  //         marks: text.includes("**") ? [{ type: "bold" }] : [], // Apply bold if ** is found
  //       },
  //     ],
  //   }));

  //   // Dispatch the structured content to the editor
  //   dispatch(setPasteContent(editorContent));

  //   // Optionally set the content in the editor
  //   editor.commands.setContent(editorContent);

  //   // Navigate after dispatch
  //   let url = projectTitle.toString().toLowerCase().replace(/\s+/g, "-");
  //   navigate(`/projects/${url}/document`);
  // };

  const handleEssayTypeChange = (event) => {
    // setEssayType(event.target.value);
    dispatch(setEssayType(event.target.value));
  };

  const handleWordCountChange = (event) => {
    // setWordCount(event.target.value);
    dispatch(setWordCount(event.target.value));
  };

  const handleIncludeHeadingChange = (event) => {
    // setIncludeHeading(event.target.checked);
    dispatch(setIncludeHeading(event.target.checked));
  };

  // const handleTopicChange = (event) => {
  //   setTopic(event.target.value);
  // };

  const handleTopicChange = (event) => {
    const value = event.target.value;

    // Allow pasting but truncate to maxCharacters if exceeded
    if (value.length > maxCharacters) {
      // setTopic(value.slice(0, maxCharacters));
      // dispatch(setTopic(value.slice(0, maxCharacters)));
      dispatch(
        setEssayTopic({
          title: projectTitle,
          topic: value.slice(0, maxCharacters),
        })
      );
      setErrors((prev) => ({
        ...prev,
        topic: "Character limit exceeded",
      }));
    } else {
      // setTopic(value);
      // dispatch(setTopic(value));
      dispatch(setEssayTopic({ title: projectTitle, topic: value }));
      setErrors((prev) => ({ ...prev, topic: "" })); // Clear error if within limit
    }
  };

  const handleDescriptionChange = (event) => {
    const value = event.target.value;

    if (value.length > maxCharactersDesc) {
      // If the value exceeds the limit, truncate it and show an error
      dispatch(setDescription(value.slice(0, maxCharactersDesc)));
      setErrors((prev) => ({
        ...prev,
        description: "Character limit exceeded",
      }));
    } else {
      // Update the description normally and clear any existing error
      dispatch(setDescription(value));
      setErrors((prev) => ({ ...prev, description: "" }));
    }
  };

  const handleStartWriting = async () => {
    const newErrors = {};

    // Validate fields
    if (!topic.trim()) newErrors.topic = "Essay Title is missing";
    if (!essayType) newErrors.essayType = "Please select an Essay Type";
    if (!wordCount) newErrors.wordCount = "Please select a Word Count range";

    // If there are errors, set them in state and return early
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear previous errors
    setErrors({});
    // setIsLoading(true);
    dispatch(setIsLoading(true));
    // setProgress(0);
    dispatch(resetProgress());

    // Get email and token from localStorage
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");

    // Construct the request payload
    const payload = {
      essay: true,
      topic: topic, // The topic (essay title) will be from the textarea
      essayType: essayType,
      wordsCount: wordCount,
      headings: includeHeading,
      email: email,
      token: token,
      description: description,
    };

    // Make the API request
    try {
      const response = await axios.post(`${baseDomain}/script`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in headers if needed
        },
      });
      console.log("Response:", response);

      if (response.data.data.response) {
        // setEssay(response.data.data.response);
        // dispatch(setEssay(response.data.data.response));
        Toast("success", "Essay Generated Successfully");
        dispatch(
          setEssayContent({
            title: projectTitle,
            essay: response.data.data.response,
          })
        );
      } else if (response.data.data.response === "Something went wrong!") {
        Toast("error", response.data.data.response);
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Essay Writing, ${wordCount}, ${essayType}`,
          error: response.data.data.response,
          query: topic,
        });
      } else if (response.data.data.error) {
        Toast("error", response.data.data.error);
        sendErrorMail({
          user: localStorage.getItem("email"),
          feature: `Essay Writing, ${wordCount}, ${essayType}`,
          error: response.data.data.error,
          query: topic,
          limitReachedError: true,
        });
      }
    } catch (error) {
      console.error("Error submitting the essay request:", error);
    } finally {
      // Set the loading state to false after the API call completes (success or failure)
      // setIsLoading(false);
      dispatch(setIsLoading(false));
    }
  };

  const handleNewEssay = () => {
    // setEssay(""); // Reset the essay to go back to the form
    // dispatch(clearEssay());
    // dispatch(clearTopic());
    dispatch(clearEssay(projectTitle));
    dispatch(clearTopic(projectTitle));
    // setDescription("");
    dispatch(clearDescription());
  };

  const handleCopyEssay = () => {
    navigator.clipboard.writeText(essay).then(() => {
      // Toast("success", "Essay copied to clipboard!");
      setTooltipOpen(true); // Show the tooltip
      setTimeout(() => setTooltipOpen(false), 2000);
    });
  };

  return (
    <RootStyle
      sx={{
        backgroundImage: essay ? "none" : `url(${hero_bg})`,
        backgroundSize: "cover",
        height: "full-screen",
      }}
    >
      {!user?.essayLimitsAdded ? (
        <GetStarted />
      ) : (
        <>
          {essay ? (
            <EssayDisplay
              essay={essay}
              onCopy={handleCopyEssay}
              tooltipOpen={tooltipOpen}
              onNew={handleNewEssay}
              topic={topic}
              handlePasteEssay={handlePasteEssay}
            />
          ) : (
            <NewEssay
              topic={topic}
              handleTopicChange={handleTopicChange}
              progress={progress}
              setProgress={setProgress}
              essayType={essayType}
              handleEssayTypeChange={handleEssayTypeChange}
              wordCount={wordCount}
              handleWordCountChange={handleWordCountChange}
              includeHeading={includeHeading}
              handleIncludeHeadingChange={handleIncludeHeadingChange}
              isSmall={isSmall}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleStartWriting={handleStartWriting}
              errors={errors}
              setErrors={setErrors}
              user={user}
              maxCharacters={maxCharacters}
              maxCharactersDesc={maxCharactersDesc}
              description={description}
              handleDescriptionChange={handleDescriptionChange}
            />
          )}
        </>
      )}
    </RootStyle>
  );
};

export default Essay;
